import {IconSearch, IconX} from '@tabler/icons-react';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Pagination from '../components/Pagination';
import ProductCard from '../components/ProductCard';
import ProductFilter from '../components/ProductFilter';
import {apiUrl} from '../routes/AdminRoutes';

const ProductSearch = () => {
  const {t, i18n} = useTranslation();
  const [searchParams, setSearchParams] = useState({
    term: '',
    filters: {},
    sortBy: null,
    sortOrder: 'asc',
  });
  const [products, setProducts] = useState({
    all: [],
    filtered: [],
    loading: true,
    error: null,
  });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 5,
  });
  const [expandedProductId, setExpandedProductId] = useState(null);

  const handleProductExpand = (productId) => {
    setExpandedProductId((prevId) => (prevId === productId ? null : productId));
  };

  const fetchProducts = useCallback(async () => {
    try {
      setProducts((prev) => ({...prev, loading: true, error: null}));
      const apiLanguage = i18n.language === 'zh' ? 'cn' : 'en';
      const response = await fetch(`${apiUrl}/api/products`, {
        headers: {'X-Custom-Language': apiLanguage},
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || t('productSearch.fetchError'));
      }
      const {products} = await response.json();

      const categoryPriority = {LDPE: 0, HDPE: 1, PP: 2, 精致蜡: 3, 'Modified Wax': 3};

      const sortedProducts = products.sort((a, b) => {
        const getCategoryIndex = (product) => {
          for (const [category, index] of Object.entries(categoryPriority)) {
            if (product.category.includes(category)) return index;
          }
          return 4; // 其他类别
        };

        const indexDiff = getCategoryIndex(a) - getCategoryIndex(b);
        return indexDiff || a.title.localeCompare(b.title);
      });

      // console.log('Fetched and sorted product data:', sortedProducts);
      setProducts((prev) => ({
        ...prev,
        all: sortedProducts,
        filtered: sortedProducts,
        loading: false,
      }));
    } catch (err) {
      console.error('Error fetching products:', err);
      setProducts((prev) => ({...prev, error: err.message, loading: false}));
    }
  }, [i18n.language, t]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const filterAndSortProducts = useCallback(() => {
    const {term, filters, sortBy, sortOrder} = searchParams;
    let result = products.all.filter(
      (product) =>
        (!term ||
          product.title.toLowerCase().includes(term.toLowerCase()) ||
          product.description.toLowerCase().includes(term.toLowerCase())) &&
        (!filters.category || product.category === filters.category) &&
        (!filters.application || product.applications.includes(filters.application))
    );

    if (sortBy) {
      const sortKeys = {
        viscosity: ['viscosity', '粘度'],
        softeningPoint: ['softeningPoint', '软化点'],
      };
      result.sort((a, b) => {
        const getValue = (product) => {
          const spec = product.spec.find((s) =>
            sortKeys[sortBy].some((key) => s.item.toLowerCase().includes(key))
          );
          return spec ? parseFloat(spec.result.split('±')[0]) || 0 : 0;
        };
        const diff = getValue(a) - getValue(b);
        return sortOrder === 'asc' ? diff : -diff;
      });
    }

    setProducts((prev) => ({...prev, filtered: result}));
    setPagination((prev) => ({...prev, currentPage: 1}));
  }, [searchParams, products.all]);

  useEffect(() => {
    filterAndSortProducts();
  }, [filterAndSortProducts]);

  const handleSearch = (e) => {
    e.preventDefault();
    filterAndSortProducts();
  };

  const clearSearch = () => {
    setSearchParams((prev) => ({...prev, term: ''}));
  };

  const handleFilterChange = (newFilters, newSortBy, newSortOrder) => {
    setSearchParams((prev) => ({
      ...prev,
      filters: newFilters,
      sortBy: newSortBy,
      sortOrder: newSortOrder,
    }));
  };

  const handlePrevPage = () => {
    setPagination((prev) => ({
      ...prev,
      currentPage: Math.max(prev.currentPage - 1, 1),
    }));
  };

  const handleNextPage = () => {
    setPagination((prev) => ({
      ...prev,
      currentPage: Math.min(
        prev.currentPage + 1,
        Math.ceil(products.filtered.length / prev.itemsPerPage)
      ),
    }));
  };

  const paginatedProducts = products.filtered.slice(
    (pagination.currentPage - 1) * pagination.itemsPerPage,
    pagination.currentPage * pagination.itemsPerPage
  );

  return (
    <div className='container px-4 pb-6 mx-auto max-w-7xl'>
      <form
        onSubmit={handleSearch}
        className='mb-6'>
        <div className='relative'>
          <input
            type='text'
            value={searchParams.term}
            onChange={(e) => setSearchParams((prev) => ({...prev, term: e.target.value}))}
            placeholder={t('productSearch.searchPlaceholder')}
            className='w-full p-4 pr-12 text-lg font-normal rounded-md outline outline-1 outline-neutral-400 hover:outline-2 focus:outline-2 focus:outline-blue-500 outline-offset-[-1px] placeholder:text-neutral-500'
          />
          {searchParams.term && (
            <button
              type='button'
              onClick={clearSearch}
              className='absolute transform -translate-y-1/2 right-14 top-1/2'>
              <IconX size={24} />
            </button>
          )}
          <button
            type='submit'
            className='absolute transform -translate-y-1/2 right-4 top-1/2'>
            <IconSearch size={24} />
          </button>
        </div>
      </form>
      <div className='flex flex-col'>
        <div className='flex items-center justify-between mb-6'>
          <ProductFilter
            products={products.all}
            onFilterChange={handleFilterChange}
          />
        </div>
        {products.loading ? (
          <div>{t('common.loading')}</div>
        ) : products.error ? (
          <div>
            {t('common.error')}: {products.error}
          </div>
        ) : (
          <>
            <div className='space-y-6'>
              {paginatedProducts.map((product) => (
                <ProductCard
                  key={product.id}
                  product={product}
                  isExpanded={expandedProductId === product.id}
                  onExpand={() => handleProductExpand(product.id)}
                />
              ))}
            </div>
            <Pagination
              currentPage={pagination.currentPage}
              totalPages={Math.ceil(products.filtered.length / pagination.itemsPerPage)}
              onPrevPage={handlePrevPage}
              onNextPage={handleNextPage}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ProductSearch;
