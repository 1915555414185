import React, {useState, useRef, useEffect} from 'react';
import {IconChevronDown, IconCheck} from '@tabler/icons-react';

const Dropdown = ({label, children, className = '', selectedValue = null}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // 克隆子元素并添加选中状态
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        className: `${child.props.className} ${
          child.props.value === selectedValue ? 'bg-cyan-700 text-white' : ''
        } flex items-center font-medium justify-between`,
        children: (
          <>
            <span>{child.props.children}</span>
            {child.props.value === selectedValue && <IconCheck className='w-4 h-4 ml-2' />}
          </>
        ),
      });
    }
    return child;
  });

  return (
    <div
      className={`relative ${className}`}
      ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className='flex items-center justify-between w-full px-2 py-2 text-xs font-medium bg-white rounded-lg shadow-sm text-neutral-700 outline outline-1 outline-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500'>
        {label}
        <IconChevronDown className='w-4 h-4 ml-1' />
      </button>
      {isOpen && (
        <div className='absolute z-10 w-48 mt-1 overflow-hidden bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='py-1'>{childrenWithProps}</div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
