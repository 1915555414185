import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PublicRoutes from './PublicRoutes';
import AdminRoutes from './AdminRoutes';

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path='/admin/*' element={<AdminRoutes />} />
        <Route path='/*' element={<PublicRoutes />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;