import React from 'react';
import CommonBtn from './CommonBtn';

const SpecInput = ({value, onChange, placeholder}) => (
  <input
    className='px-3 py-2 rounded-md outline outline-1 outline-neutral-400 focus:outline-2 focus:outline-blue-500 outline-offset-[-1px]'
    value={value}
    onChange={onChange}
    placeholder={placeholder}
  />
);

const SpecManager = ({specs, onChange}) => {
  const addSpec = () => {
    onChange([...specs, {item: '', result: '', unit: '', method: ''}]);
  };

  const updateSpec = (index, field, value) => {
    const newSpecs = specs.map((spec, i) => (i === index ? {...spec, [field]: value} : spec));
    onChange(newSpecs);
  };

  const deleteSpec = (index) => {
    onChange(specs.filter((_, i) => i !== index));
  };

  const specFields = [
    {key: 'item', placeholder: '项目'},
    {key: 'result', placeholder: '结果'},
    {key: 'unit', placeholder: '单位'},
    {key: 'method', placeholder: '方法'},
  ];

  return (
    <div className='flex flex-col h-fit'>
      <h4 className='mb-2 font-semibold text-left'>规格</h4>
      <div className='flex-grow mb-2 overflow-y-auto'>
        {specs.map((spec, index) => (
          <div
            key={index}
            className='grid grid-cols-5 gap-2 mb-2'>
            {specFields.map(({key, placeholder}) => (
              <SpecInput
                key={key}
                value={spec[key]}
                onChange={(e) => updateSpec(index, key, e.target.value)}
                placeholder={placeholder}
              />
            ))}
            <CommonBtn
              onClick={() => deleteSpec(index)}
              variant='secondary'
              size='small'>
              删除
            </CommonBtn>
          </div>
        ))}
      </div>
      <div className='flex justify-end'>
        <CommonBtn
          onClick={addSpec}
          variant='secondary'
          size='small'>
          添加
        </CommonBtn>
      </div>
    </div>
  );
};

export default SpecManager;
