import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {IconChevronLeft} from '@tabler/icons-react';

const Breadcrumb = ({items}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='flex justify-center w-full'>
      <nav
        className='flex items-center w-full py-4 text-base font-medium max-w-7xl'
        aria-label='Breadcrumb'>
        <button
          onClick={() => navigate(-1)}
          className='p-2 mr-4 transition-colors duration-200 rounded-full hover:bg-gray-100'
          aria-label='Go back'>
          <IconChevronLeft size={24} />
        </button>
        <ol className='inline-flex items-center space-x-1 overflow-hidden md:space-x-3'>
          {items.map((item, index) => (
            <li
              key={index}
              className='inline-flex items-center whitespace-nowrap'>
              {index > 0 && <span className='mx-2 text-gray-400'>/</span>}
              {item.link ? (
                <Link
                  to={item.link}
                  className='text-cyan-600 hover:text-cyan-800 truncate max-w-[150px]'>
                  {t(item.label)}
                </Link>
              ) : (
                <span className='text-gray-700 truncate max-w-[150px]'>{t(item.label)}</span>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;
