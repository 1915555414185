import React from 'react';
import {IconChevronLeft, IconChevronRight} from '@tabler/icons-react';

function Pagination({currentPage, totalPages, onPrevPage, onNextPage}) {
  return (
    <div className='flex justify-center py-8'>
      <button
        onClick={onPrevPage}
        className='p-2 mx-2 transition-colors duration-300 rounded text-cyan-800 hover:text-white hover:bg-neutral-700 disabled:opacity-50 disabled:cursor-not-allowed'
        disabled={currentPage === 1}>
        <IconChevronLeft size={24} stroke={2} />
      </button>
      <span className='flex items-center px-4 py-2 font-semibold text-cyan-800'>
        {currentPage} / {totalPages}
      </span>
      <button
        onClick={onNextPage}
        className='p-2 mx-2 transition-colors duration-300 rounded text-cyan-800 hover:text-white hover:bg-neutral-700 disabled:opacity-50 disabled:cursor-not-allowed'
        disabled={currentPage === totalPages}>
        <IconChevronRight size={24} stroke={2} />
      </button>
    </div>
  );
}

export default Pagination;
