import React, {useState, useEffect, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {IconSearch, IconX} from '@tabler/icons-react';
import NewsCard from '../components/NewsCard';
import Pagination from '../components/Pagination';
import {apiUrl} from '../routes/AdminRoutes';

const NewsAndEvents = () => {
  const {t, i18n} = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalCount, setTotalCount] = useState(0);

  const fetchNews = useCallback(async () => {
    try {
      setLoading(true);
      const currentLanguage = i18n.language;
      const apiLanguage = currentLanguage === 'zh' ? 'cn' : 'en';
      const skip = (currentPage - 1) * itemsPerPage;
      const limit = itemsPerPage;
      let url = `${apiUrl}/api/news?skip=${skip}&limit=${limit}`;

      if (debouncedSearchTerm) {
        url += `&search=${encodeURIComponent(debouncedSearchTerm)}`;
      }

      const response = await fetch(url, {
        headers: {
          'X-Custom-Language': apiLanguage,
        },
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || '获取新闻列表失败');
      }
      const data = await response.json();

      // console.log('Fetched news data:', data);
      setNewsData(data.news);
      setTotalCount(data.totalCount);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching news:', err);
      setError(err.message);
      setLoading(false);
    }
  }, [i18n.language, currentPage, itemsPerPage, debouncedSearchTerm]);

  useEffect(() => {
    fetchNews();
  }, [fetchNews]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 800); // 300ms 的防抖延迟

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedSearchTerm]);

  const handleSearch = (e) => {
    e.preventDefault();
    // 防抖后的搜索词会自动触发搜索，这里不需要额外操作
  };

  const clearSearch = () => {
    setSearchTerm('');
    // 清除搜索词会自动触发搜索
  };

  const totalPages = Math.ceil(totalCount / itemsPerPage);

  return (
    <div className='container px-4 py-6 mx-auto max-w-7xl'>
      <form onSubmit={handleSearch} className='mb-6'>
        <div className='relative'>
        <input
            type='text'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={t('news.search.placeholder')}
            className='w-full p-4 pr-12 text-lg font-normal rounded-md outline outline-1 outline-neutral-400 hover:outline-2 focus:outline-2 focus:outline-blue-500 outline-offset-[-1px] placeholder:text-neutral-500'
          />
          {searchTerm && (
            <button type='button' onClick={clearSearch} className='absolute transform -translate-y-1/2 right-14 top-1/2'>
              <IconX size={24} />
            </button>
          )}
          <button type='submit' className='absolute transform -translate-y-1/2 right-4 top-1/2'>
            <IconSearch size={24} />
          </button>
        </div>
      </form>

      {loading ? (
        <div className='py-8 text-center'>加载中...</div>
      ) : error ? (
        <div className='py-8 text-center text-red-500'>错误：{error}</div>
      ) : (
        <>
          <div className='space-y-4'>
            {newsData.map((news) => (
              <NewsCard key={news.id} news={news} />
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPrevPage={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            onNextPage={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          />
        </>
      )}
    </div>
  );
};

export default NewsAndEvents;
