import {
  IconPhone,
  IconMail,
  IconBrandWechat,
  IconMapPin,
  IconInfoCircle,
  IconUser,
} from '@tabler/icons-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

const CompanyInfo = () => {
  const {t, i18n} = useTranslation();

  const contactInfo = [
    {
      icon: IconUser,
      label: 'contact',
      value:
        i18n.language === 'zh'
          ? '戚浩燊先生 泰利国际贸易部经理'
          : 'Mr. Qi, International Trade Manager',
      fullWidth: true,
    },
    {
      icon: IconPhone,
      label: 'mobile',
      value: '+86 13702421731',
      href: 'tel:+8613702421731',
    },
    {
      icon: IconPhone,
      label: 'landline',
      value: '+86 0758-7789970',
      href: 'tel:+860758-7789970',
    },
    {
      icon: IconMail,
      label: 'email',
      value: 'tailiqihaoshen@gmail.com',
      href: 'mailto:tailiqihaoshen@gmail.com',
      fullWidth: true,
    },
    {
      icon: IconBrandWechat,
      label: 'whatsapp',
      value: 'TAILI WAX',
    },
    {
      icon: IconMapPin,
      label: 'address',
      value:
        i18n.language === 'zh'
          ? '中国广东省肇庆市德庆县康杰路 17 号'
          : 'No. 17, Kangjie Road, Deqing County, Zhaoqing City, Guangdong Province, China',
      fullWidth: true,
    },
    {
      icon: IconMapPin,
      label: 'postcode',
      value: '526699',
    },
  ];

  return (
    <div className='flex flex-col w-full mb-8 text-left md:mb-0 md:w-1/2'>
      <h2 className='mb-4 text-2xl font-bold md:text-3xl text-cyan-600'>{t('company_name')}</h2>
      <div className='grid grid-cols-2 gap-2 mt-4 sm:grid-cols-3'>
        {contactInfo.map((item, index) => (
          <div
            key={index}
            className={`flex items-center ${item.fullWidth ? 'col-span-2 sm:col-span-3' : ''}`}>
            <item.icon
              className='flex-shrink-0 mr-2 text-cyan-600'
              size={16}
            />
            <div className='flex flex-col'>
              <span className='text-xs font-medium text-gray-500'>
                {t(`contact.${item.label}`)}
              </span>
              {item.href ? (
                <a
                  href={item.href}
                  className='text-xs sm:text-sm hover:text-cyan-600'>
                  {item.value}
                </a>
              ) : (
                <span className='text-xs sm:text-sm'>{item.value}</span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const QuickLinks = () => {
  const {t} = useTranslation();

  const footerCategories = [
    {
      name: 'footer.about',
      icon: <IconInfoCircle size={22} />,
      items: [
        {label: 'company_intro', link: '/about#CompanyIntro'},
        {label: 'core_business', link: '/about#CoreBusiness'},
        {label: 'research_capability', link: '/about#ResearchCapability'},
        {label: 'production_capacity', link: '/about#ProductionCapacity'},
        {label: 'business_performance', link: '/about#BusinessPerformance'},
      ],
    },
  ];

  return (
    <div className='flex flex-col h-full pb-2 md:w-1/2'>
      {footerCategories.map((category, index) => (
        <div
          key={index}
          className='mb-6'>
          <div className='flex items-center mb-3 text-2xl font-bold md:text-3xl text-cyan-600'>
            <span >{t(category.name)}</span>
          </div>
          <p className='w-full text-sm leading-relaxed text-left h-fit md:text-base text-neutral-500 max-w-prose'>
            {t('company_description')}
          </p>
          <div className='grid grid-cols-2 gap-2 mt-4 sm:grid-cols-3'>
            {category.items.map((item, itemIndex) => (
              <a
                key={itemIndex}
                href={item.link}
                className='text-sm font-normal text-left underline underline-offset-4 text-neutral-600 hover:text-cyan-600 focus:outline-none'>
                {t(`footer.${item.label}`)}
              </a>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const LegalInfo = () => {
  const {t} = useTranslation();
  return (
    <div className='flex flex-col items-center justify-between pt-4 text-xs border-t border-neutral-200 md:flex-row'>
      <p className='mb-2 md:mb-0 text-neutral-500'>{t('copyright')}</p>
      <div className='flex items-center'>
        <p className='ml-4 transition-colors cursor-pointer hover:text-cyan-600'>{t('icp')}</p>
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <footer className='px-6 py-4 font-medium bg-neutral-100 text-neutral-600 '>
      <div className='mx-auto max-w-7xl'>
        <div className='flex flex-col mb-4 md:flex-row md:space-x-8'>
          <CompanyInfo />
          <QuickLinks />
        </div>
        <LegalInfo />
      </div>
    </footer>
  );
};

export default Footer;
