import {IconAlertCircle, IconPhoto} from '@tabler/icons-react';
import {deleteQiniuFile, uploadToQiniu} from './UploadQiniu';
import {useEffect, useState} from 'react';

const CoverUploader = ({image, setImage,articleId, aspectRatio = '3:2', maxSize = 2, width = 300, height = 200, format = 'JPG'}) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [previewImage, setPreviewImage] = useState(image);
  const [error, setError] = useState(null);

  useEffect(() => {
    setPreviewImage(image);
  }, [image]);

  const processImage = (file) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const aspectRatioValues = aspectRatio.split(':').map(Number);
        const targetRatio = aspectRatioValues[0] / aspectRatioValues[1];

        let sWidth, sHeight, sx, sy;
        if (img.width / img.height > targetRatio) {
          sHeight = img.height;
          sWidth = img.height * targetRatio;
          sx = (img.width - sWidth) / 2;
          sy = 0;
        } else {
          sWidth = img.width;
          sHeight = img.width / targetRatio;
          sx = 0;
          sy = (img.height - sHeight) / 2;
        }

        // 创建一个临时画布用于裁剪
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = sWidth;
        tempCanvas.height = sHeight;
        const tempCtx = tempCanvas.getContext('2d');
        tempCtx.drawImage(img, sx, sy, sWidth, sHeight, 0, 0, sWidth, sHeight);

        // 将裁剪后的图像缩放到目标尺寸
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(tempCanvas, 0, 0, sWidth, sHeight, 0, 0, width, height);

        // 压缩图片
        const compressAndResize = (quality = 0.95) => {
          canvas.toBlob(
            (blob) => {
              if (blob.size > maxSize * 1024 * 1024 && quality > 0.5) {
                // 如果文件仍然太大，继续压缩
                compressAndResize(quality - 0.05);
              } else {
                resolve(new File([blob], file.name, {type: `image/${format.toLowerCase()}`}));
              }
            },
            `image/${format.toLowerCase()}`,
            quality
          );
        };

        compressAndResize();
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        setError(null);
        setUploadProgress(0);

        // 如果存在当前图片，先删除它
        if (image) {
          const fileKey = image.split('/').pop(); // 从URL中提取文件名作为key
          await deleteQiniuFile(fileKey);
          // console.log('旧图片删除成功');
        }

        // 处理图片：裁剪、缩放和压缩
        const processedFile = await processImage(file);

        // 创建预览URL
        const previewUrl = URL.createObjectURL(processedFile);
        setPreviewImage(previewUrl);

        if (!articleId) {
          throw new Error('缺少文章ID，无法上传封面图');
        }

        // 上传到七牛
        const imageUrl = await uploadToQiniu(processedFile, (progress) => {
          setUploadProgress(progress);
        }, articleId);

        setImage(imageUrl);
        setUploadProgress(100);
      } catch (error) {
        console.error('图片操作失败', error);
        setError('图片操作失败，请重试');
        setUploadProgress(0);
      }
    }
  };


  const getAspectRatio = () => {
    if (typeof aspectRatio === 'string' && aspectRatio.includes(':')) {
      const [w, h] = aspectRatio.split(':').map(Number);
      return (h / w) * 100;
    }
    return (height / width) * 100;
  };

  const aspectRatioStyle = {
    paddingTop: `${getAspectRatio()}%`,
  };

  return (
    <div className='relative flex flex-col w-full'>
      <div className='relative w-full overflow-hidden bg-gray-100 border rounded-md cursor-pointer' style={aspectRatioStyle}>
        <div className='absolute inset-0 flex items-center justify-center'>
          {previewImage ? (
            <img src={previewImage} alt='预览图' className='object-cover w-full h-full' />
          ) : (
            <div className='font-semibold text-center text-neutral-700'>
              <IconPhoto size={48} className='mx-auto mb-2' />
              <p className=''>{`${width} x ${height} ${format} 格式`}</p>
              <p className='mt-2 text-sm text-blue-500'>{`上传封面图 最大${maxSize}MB`}</p>
            </div>
          )}
        </div>
        {uploadProgress > 0 && uploadProgress < 100 && (
          <div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-50'>
            <div className='w-3/4 h-4 bg-white rounded-full'>
              <div className='h-full bg-blue-500 rounded-full' style={{width: `${uploadProgress}%`}}></div>
            </div>
            <span className='absolute text-white'>{`${Math.round(uploadProgress)}%`}</span>
          </div>
        )}
      </div>
      <input type='file' onChange={handleImageChange} accept='image/*' className='absolute inset-0 w-full h-full opacity-0 cursor-pointer' />
      {error && (
        <div className='absolute bottom-0 left-0 right-0 p-2 text-sm text-white bg-red-500'>
          <div className='flex items-center'>
            <IconAlertCircle size={16} className='mr-2' />
            <span>{error}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoverUploader;
