import {
  IconAward,
  IconBuilding,
  IconBuildingFactory,
  IconCalendar,
  IconCar,
  IconCertificate,
  IconChevronRight,
  IconCircle,
  IconCoin,
  IconFlask,
  IconMapPin,
  IconPaint,
  IconPill,
  IconPrinter,
  IconRuler,
  IconShirt,
  IconTrendingUp,
  IconUsers,
} from '@tabler/icons-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import AnimatedNumber from '../components/AnimatedNumber';

function CompanyIntro() {
  const {t, i18n} = useTranslation();

  const companyInfo = [
    {
      icon: IconCalendar,
      label: 'about.companyIntro.established',
      value: i18n.language === 'en' ? 'April 26, 2017' : '2017年4月26日',
    },
    {icon: IconMapPin, label: 'about.companyIntro.location', value: t('about.companyLocation')},
    {
      icon: IconRuler,
      label: 'about.companyIntro.totalArea',
      value: i18n.language === 'en' ? '5,713 m²' : '5713平方米',
    },
    {
      icon: IconBuilding,
      label: 'about.companyIntro.constructionArea',
      value: i18n.language === 'en' ? '5,593 m²' : '5593平方米',
    },
    {
      icon: IconCoin,
      label: 'about.companyIntro.registeredCapital',
      value: i18n.language === 'en' ? '5M RMB' : '500万元',
    },
  ];

  return (
    <section className='py-12 font-medium bg-white text-neutral-900'>
      <div className='mx-auto '>
        <h2 className='pb-4 mb-12 text-3xl font-bold text-left border-b-2 border-neutral-500'>
          {t('about.companyIntro.title')}
        </h2>
        <div className='flex flex-col lg:flex-row lg:items-start lg:space-x-16'>
          <div className='lg:w-1/2'>
            <p className='mb-10 text-lg leading-relaxed text-left '>
              {t('about.companyIntro.description', {
                name: t('about.companyName'),
                established: i18n.language === 'en' ? 'April 26, 2017' : '2017年4月26日',
                founders: t('about.founders'),
                interpolation: {escapeValue: false},
              })}
            </p>
            <ul className='space-y-6'>
              {companyInfo.map((item, index) => (
                <li
                  key={index}
                  className='flex items-center text-sm text-left'>
                  <item.icon
                    className='flex-shrink-0 mr-4 '
                    size={20}
                  />
                  <span className='text-neutral-600'>
                    {t(item.label)}:{' '}
                    <span className='font-medium text-neutral-900'>{item.value}</span>
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className='h-full mt-12 lg:mt-0 lg:w-1/2'>
            <img
              src='https://cdn.tailiwax.com/profile/CompanyFront.jpg'
              alt={t('about.companyIntro.companyFrontAlt')}
              className='object-cover w-full h-full'
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function ResearchCapability() {
  const {t, i18n} = useTranslation();
  const patentImages = [
    'https://cdn.tailiwax.com/profile/Patent_01.jpg',
    'https://cdn.tailiwax.com/profile/Patent_02.jpg',
    'https://cdn.tailiwax.com/profile/Patent_03.jpg',
  ];

  const patents = [
    {year: 2019, utility: 10, software: 2},
    {year: 2020, utility: 2, software: 0},
    {year: 2022, utility: 5, software: 0},
  ];

  const labInvestments = [
    {year: 2020, amount: '800,000', description: 'about.researchCapability.labInvestment'},
    {
      date: i18n.language === 'en' ? 'September 2020' : '2020年9月',
      description: 'about.researchCapability.cooperationAgreement',
    },
    {description: 'about.researchCapability.jointDevelopment'},
  ];

  const highTechCertificates = [
    {
      src: 'https://cdn.tailiwax.com/profile/HIghTechC_01.jpg',
      alt: t('about.researchCapability.highTechCertificate1'),
    },
    {
      src: 'https://cdn.tailiwax.com/profile/HighTechC_02.jpg',
      alt: t('about.researchCapability.highTechCertificate2'),
    },
  ];

  const labImages = [
    'https://cdn.tailiwax.com/profile/lab_01.jpg',
    'https://cdn.tailiwax.com/profile/lab_02.jpg',
    'https://cdn.tailiwax.com/profile/lab_03.jpg',
    'https://cdn.tailiwax.com/profile/lab_04.jpg',
  ];

  return (
    <section className='py-12 '>
      <div className='mx-auto '>
        <h2 className='pb-4 mb-12 text-3xl font-bold text-left border-b-2 text-neutral-900 border-neutral-500'>
          {t('about.researchCapability.title')}
        </h2>
        {/* 专利信息 */}
        <div className='mb-12'>
          <div className='flex flex-col lg:flex-row'>
            <div className='lg:w-1/3'>
              <h3 className='py-4 text-xl font-medium text-left text-neutral-800'>
                {t('about.researchCapability.patentsTitle')}
              </h3>
              <ul className='space-y-4'>
                {patents.map((patent, index) => (
                  <li
                    key={index}
                    className='p-4 text-lg text-left rounded-lg shadow-sm bg-gray-50'>
                    <span className='text-2xl font-bold text-cyan-600'>{patent.year}</span>
                    <div className='mt-2'>
                      {patent.utility > 0 && (
                        <span className='mr-4'>
                          {t('about.researchCapability.utilityPatent')}:
                          <span className='ml-1 text-xl font-semibold text-cyan-700'>
                            {patent.utility}
                          </span>
                        </span>
                      )}
                      {patent.software > 0 && (
                        <span>
                          {t('about.researchCapability.softwarePatent')}:
                          <span className='ml-1 text-xl font-semibold text-cyan-700'>
                            {patent.software}
                          </span>
                        </span>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className='grid grid-cols-3 gap-2 lg:w-2/3'>
              {patentImages.map((patentImage, i) => (
                <div
                  key={i}
                  className='relative overflow-hidden group'>
                  <img
                    src={patentImage}
                    alt={t(`about.researchCapability.patentCertificate${i + 1}`)}
                    className='object-cover w-full h-full transition-transform duration-300 group-hover:scale-110'
                  />
                  <div className='absolute inset-0 transition-opacity duration-300 opacity-0 bg-gradient-to-t from-neutral-900 to-transparent group-hover:opacity-70'></div>
                  <p className='absolute bottom-0 left-0 right-0 p-4 text-sm text-white transition-opacity duration-300 opacity-0 group-hover:opacity-100'>
                    {t(`about.researchCapability.patentCertificate${i + 1}`)}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* 实验室投资和其他研发能力 */}
        <div className='mb-12'>
          <div className='flex flex-col gap-8 lg:flex-row'>
            <div className='grid w-1/2 grid-cols-2 gap-4 mt-8'>
              {labImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={t('about.researchCapability.labImageAlt', {number: index + 1})}
                  className='w-full h-auto rounded-lg shadow-md'
                />
              ))}
            </div>
            <div className='lg:w-1/2'>
              <h3 className='pb-4 text-xl font-medium text-left text-neutral-800'>
                {t('about.researchCapability.labInvestmentTitle')}
              </h3>
              <ul className='space-y-4'>
                {labInvestments.map((item, index) => (
                  <li
                    key={index}
                    className='text-lg text-left text-neutral-700'>
                    {t(item.description, {amount: item.amount, year: item.year, date: item.date})}
                  </li>
                ))}
              </ul>
              <ul className='mt-8 space-y-4'>
                <li className='flex items-center text-lg'>
                  <IconFlask
                    className='flex-shrink-0 mr-3 text-cyan-600'
                    size={24}
                  />
                  <span className='text-neutral-700'>
                    {t('about.researchCapability.advancedLab')}:
                    <span className='ml-1 font-medium text-neutral-900'>
                      {t('about.researchCapability.labSize', {size: '200'})}
                    </span>
                  </span>
                </li>
                <li className='flex items-center text-lg'>
                  <IconUsers
                    className='flex-shrink-0 mr-3 text-cyan-600'
                    size={24}
                  />
                  <span className='text-neutral-700'>
                    {t('about.researchCapability.engineeringStaff')}:
                    <span className='ml-1 font-medium text-neutral-900'>
                      {t('about.researchCapability.staffCount', {count: 8})}
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* 高新技术企业 */}
        <div className=''>
          <div className='flex flex-col lg:flex-row'>
            <div className='lg:w-1/3'>
              <h3 className='pb-4 text-xl font-medium text-left text-neutral-800'>
                {t('about.researchCapability.highTechTitle')}
              </h3>
              <p className='text-lg text-left text-neutral-700'>
                {t('about.researchCapability.highTechAward', {year: 2020})}
              </p>
            </div>
            <div className='flex justify-end gap-2 lg:w-2/3'>
              {highTechCertificates.map((cert, index) => (
                <div
                  key={index}
                  className='relative flex justify-end h-64 overflow-hidden group'>
                  <img
                    src={cert.src}
                    alt={cert.alt}
                    className='object-contain h-full transition-transform duration-300 group-hover:scale-110'
                  />
                  <div className='absolute inset-0 transition-opacity duration-300 opacity-0 bg-gradient-to-t from-neutral-900 to-transparent group-hover:opacity-70'></div>
                  <p className='absolute bottom-0 left-0 right-0 p-4 text-sm text-white transition-opacity duration-300 opacity-0 group-hover:opacity-100'>
                    {cert.alt}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function CoreBusiness() {
  const {t, i18n} = useTranslation();

  const products = t('about.coreBusiness.products', {returnObjects: true});
  const industries = t('about.coreBusiness.industries', {returnObjects: true});
  const applications = t('about.coreBusiness.applications', {returnObjects: true});

  const industryIcons = {
    Plastics: IconBuildingFactory,
    Adhesives: IconBuilding,
    Printing: IconPrinter,
    Medicine: IconPill,
    Rubber: IconCar,
    Textiles: IconShirt,
    Coatings: IconPaint,
  };

  const getIndustryIcon = (industryName) => {
    if (i18n.language === 'zh') {
      const englishName = Object.keys(industryIcons).find(
        (key) => t(`about.coreBusiness.${key}`) === industryName
      );
      return industryIcons[englishName] || IconBuilding;
    } else {
      return industryIcons[industryName] || IconBuilding;
    }
  };

  return (
    <section className='py-12 bg-white'>
      <div className='mx-auto '>
        <h2 className='pb-4 mb-12 text-3xl font-bold text-left border-b-2 text-neutral-900 border-neutral-500'>
          {t('about.coreBusiness.title')}
        </h2>
        <div className='flex flex-col lg:flex-row lg:space-x-16'>
          <div className='lg:w-1/2'>
            <p className='mb-8 text-lg leading-relaxed text-left text-neutral-700'>
              {t('about.coreBusiness.description')}
            </p>
            <h3 className='pb-2 mb-4 text-xl font-medium text-left border-b text-neutral-900 border-neutral-200'>
              {t('about.coreBusiness.productsTitle')}
            </h3>
            <ul className='grid grid-cols-2 gap-4 mb-8'>
              {products.map((product, index) => (
                <li
                  key={index}
                  className='flex items-center text-sm text-neutral-600'>
                  <IconChevronRight
                    size={16}
                    className='mr-2 text-neutral-400'
                  />
                  {product}
                </li>
              ))}
            </ul>
            <h3 className='pb-2 mb-4 text-xl font-medium text-left border-b text-neutral-900 border-neutral-200'>
              {t('about.coreBusiness.applicationsTitle')}
            </h3>
            <ul className='space-y-2'>
              {applications.map((app, index) => (
                <li
                  key={index}
                  className='flex items-start text-sm text-neutral-600'>
                  <IconCircle
                    size={16}
                    className='mt-1 mr-2 text-neutral-400'
                  />
                  <span>{app}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className='mt-12 space-y-8 lg:mt-0 lg:w-1/2'>
            <div className='grid grid-cols-2 gap-2'>
              {[1, 2, 3, 4].map((i) => (
                <div
                  key={i}
                  className='aspect-[16/9] overflow-hidden'>
                  <img
                    src={`https://cdn.tailiwax.com/profile/product_0${i}.jpeg`}
                    alt={t('about.coreBusiness.productImageAlt', {number: i})}
                    className='object-cover w-full h-full'
                  />
                </div>
              ))}
            </div>
            <h3 className='pb-2 mt-8 mb-4 text-xl font-medium text-left border-b text-neutral-900 border-neutral-200'>
              {t('about.coreBusiness.industriesTitle')}
            </h3>
            <div className='grid grid-cols-2 gap-6'>
              {industries.map((industry, index) => {
                const IconComponent = getIndustryIcon(industry.name);
                return (
                  <div
                    key={index}
                    className='flex items-center p-2 rounded-full bg-neutral-100'>
                    <div className='flex items-center justify-center w-10 h-10 mr-3'>
                      <IconComponent
                        size={24}
                        className='text-neutral-600'
                      />
                    </div>
                    <span className='text-base font-medium text-neutral-700'>{industry.name}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function ProductionCapacity() {
  const {t, i18n} = useTranslation();

  const productionInfo = [
    {
      label: 'about.productionCapacity.installationDate',
      value: i18n.language === 'en' ? 'October 2018' : '2018年10月',
    },
    {label: 'about.productionCapacity.polyolefinUnits', value: 7},
    {label: 'about.productionCapacity.sprayingTowers', value: 1},
    {label: 'about.productionCapacity.polyethyleneWax', value: 6000},
    {label: 'about.productionCapacity.polypropyleneWax', value: 2500},
    {label: 'about.productionCapacity.compoundWax', value: 1000},
    {label: 'about.productionCapacity.totalCapacity', value: 9500},
    {label: 'about.productionCapacity.annualValue', value: 12000},
  ];

  const renderValue = (item) => {
    if (typeof item.value === 'number') {
      let value = item.value;
      let decimals = 0;
      let suffix = '';

      if (item.label.includes('annualValue')) {
        if (i18n.language === 'en') {
          value = item.value / 100; // 转换为百万
          decimals = 2; // 保留两位小数
          suffix = ' million RMB';
        } else {
          suffix = '万元';
        }
      } else if (item.label.includes('Wax') || item.label.includes('totalCapacity')) {
        suffix = i18n.language === 'en' ? ' tons' : '吨';
      }

      return (
        <AnimatedNumber
          value={value}
          duration={2000}
          decimals={decimals}
          suffix={suffix}
        />
      );
    }
    return item.value;
  };

  return (
    <section className='py-12 bg-white'>
      <div className='mx-auto '>
        <h2 className='pb-4 mb-12 text-3xl font-bold text-left border-b-2 text-neutral-900 border-neutral-500'>
          {t('about.productionCapacity.title')}
        </h2>
        <div className='flex flex-col lg:flex-row lg:items-stretch lg:space-x-16'>
          <div className='flex flex-col justify-between lg:w-1/2'>
            <div>
              <p className='mb-8 text-lg leading-relaxed text-left text-neutral-700'>
                {t('about.productionCapacity.description', {
                  installationDate: productionInfo[0].value,
                  polyolefinUnits: productionInfo[1].value,
                  sprayingTowers: productionInfo[2].value,
                })}
              </p>

              <h3 className='mb-6 text-2xl font-normal text-cyan-700'>
                {t('about.productionCapacity.capacityTitle')}
              </h3>
              <div className='grid grid-cols-2 gap-6'>
                {productionInfo.slice(3, 7).map((item, index) => (
                  <div
                    key={index}
                    className='p-4 transition-all duration-300 border-2 rounded-lg border-neutral-300 hover:shadow-lg hover:border-neutral-200'>
                    <p className='mb-1 text-sm font-bold uppercase text-cyan-600'>
                      {t(item.label)}
                    </p>
                    <p className='text-2xl font-light text-neutral-800'>{renderValue(item)}</p>
                  </div>
                ))}
                <div className='col-span-2 p-4 transition-all duration-300 border-2 rounded-lg border-neutral-300 hover:shadow-lg'>
                  <p className='mb-1 text-sm font-bold uppercase text-cyan-700'>
                    {t(productionInfo[7].label)}
                  </p>
                  <p className='text-3xl font-bold text-cyan-800'>
                    {renderValue(productionInfo[7])}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col justify-between mt-12 lg:mt-0 lg:w-1/2'>
            <div className='mb-6 h-80'>
              <img
                src='https://cdn.tailiwax.com/profile/factory_interior.jpeg'
                alt={t('about.productionCapacity.imageAlt')}
                className='object-cover w-full h-full rounded-lg shadow-lg'
              />
            </div>
            <div className='grid grid-cols-2 gap-6'>
              {productionInfo.slice(1, 3).map((item, index) => (
                <div
                  key={index}
                  className='p-4 transition-all duration-300 border-2 rounded-lg border-neutral-300 hover:shadow-lg hover:border-neutral-200'>
                  <p className='mb-1 text-sm font-bold uppercase text-cyan-600'>{t(item.label)}</p>
                  <p className='text-3xl font-medium text-cyan-700'>{renderValue(item)}</p>
                  <p className='mt-1 text-sm font-medium text-neutral-600'>
                    {t(`about.productionCapacity.${index === 0 ? 'sets' : 'set'}`)}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function BusinessPerformance() {
  const {t, i18n} = useTranslation();

  const taxRevenueData = [
    {year: 2020, revenue: 127},
    {year: 2021, revenue: 153.8},
    {year: 2022, revenue: 277.8},
  ];

  const certificates = [
    {
      src: 'https://cdn.tailiwax.com/profile/Certificate_01.jpg',
      alt: t('about.businessPerformance.certificate1Alt'),
    },
    {
      src: 'https://cdn.tailiwax.com/profile/Certificate_02.jpg',
      alt: t('about.businessPerformance.certificate2Alt'),
    },
    {
      src: 'https://cdn.tailiwax.com/profile/Certificate_03.jpg',
      alt: t('about.businessPerformance.certificate3Alt'),
    },
    {
      src: 'https://cdn.tailiwax.com/profile/Certificate_04.jpg',
      alt: t('about.businessPerformance.certificate4Alt'),
    },
    {
      src: 'https://cdn.tailiwax.com/profile/Certificate_05.jpg',
      alt: t('about.businessPerformance.certificate5Alt'),
    },
  ];

  // console.log('Rendering BusinessPerformance');

  return (
    <section className='py-12 '>
      <h2 className='pb-4 mb-8 text-3xl font-bold text-left border-b-2 text-neutral-900 border-neutral-500'>
        {t('about.businessPerformance.title')}
      </h2>
      <div className='mb-8'>
        <h3 className='flex items-center mb-4 text-2xl font-semibold text-cyan-700'>
          <IconTrendingUp className='mr-2' />
          {t('about.businessPerformance.taxRevenue')}
        </h3>
        <div className='grid grid-cols-1 gap-4 md:grid-cols-3'>
          {taxRevenueData.map((data, index) => (
            <div
              key={index}
              className='p-4 text-center rounded-lg bg-gray-50'>
              <p className='text-4xl font-bold text-cyan-600'>
                <AnimatedNumber
                  value={i18n.language === 'en' ? data.revenue / 100 : data.revenue}
                  suffix={i18n.language === 'en' ? ' million RMB' : '万元'}
                  decimals={i18n.language === 'en' ? 3 : 1}
                  duration={2000}
                />
              </p>
              <p className='text-neutral-600'>
                {t('about.businessPerformance.year', {year: data.year})}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className='mb-8'>
        <h3 className='flex items-center mb-4 text-2xl font-semibold text-cyan-700'>
          <IconCertificate className='mr-2' />
          {t('about.businessPerformance.taxCertificate')}
        </h3>
        <div className='flex flex-col items-center space-y-4 md:flex-row md:items-center md:space-y-0 md:space-x-8'>
          <div className='w-full md:w-2/5'>
            <img
              src='https://cdn.tailiwax.com/profile/TaxCertificate.jpg'
              alt={t('about.businessPerformance.classTaxpayerCertificate')}
              className='w-full rounded-lg shadow-md'
            />
          </div>
          <div className='flex-1 space-y-4'>
            <p className='text-2xl font-medium text-neutral-800'>
              {t('about.businessPerformance.awardedIn', {year: 2022})}
            </p>
            <p className='text-3xl font-bold text-cyan-700'>
              {t('about.businessPerformance.classATaxpayerTitle')}
            </p>
            <p className='text-xl font-normal text-neutral-700'>
              {t('about.businessPerformance.integrityBased')}
            </p>
            <p className='text-xl font-normal text-neutral-700'>
              {t('about.businessPerformance.recognizedByAll')}
            </p>
          </div>
        </div>
      </div>

      <div>
        <h3 className='flex items-center mb-4 text-2xl font-semibold text-cyan-700'>
          <IconAward className='mr-2' />
          {t('about.businessPerformance.otherAwards')}
        </h3>
        <div className='grid grid-cols-2 gap-4 md:grid-cols-5'>
          {certificates.map((cert, index) => (
            <div
              key={index}
              className='aspect-[3/4] relative rounded-lg shadow-md overflow-hidden'>
              <img
                src={cert.src}
                alt={cert.alt}
                className='absolute inset-0 object-cover w-full h-full'
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

function About() {

  return (
    <div className='container px-8 mx-auto font-medium sm:px-8 md:px-6 lg:px-4 max-w-7xl '>
      <section id='CompanyIntro'>
        <CompanyIntro />
      </section>
      <section id='CoreBusiness'>
        <CoreBusiness />
      </section>
      <section id='ResearchCapability'>
        <ResearchCapability />
      </section>
      <section id='ProductionCapacity'>
        <ProductionCapacity />
      </section>
      <section id='BusinessPerformance'>
        <BusinessPerformance />
      </section>
    </div>
  );
}

export default About;
