import React from 'react';
import {isMobile} from 'react-device-detect';
import HeroSlider from '../components/HeroSlider';
import ArticleGrid from '../components/Gird';
import ArticleSlide from '../components/ArticleSlide';

function Home() {

  return (
    <div className='w-full home-container'>
      <div className='w-full bg-cyan-700'>
        <div className='w-full mx-auto h-fit'>
          <HeroSlider />
        </div>
      </div>
      <main className='w-full mx-auto max-w-7xl'>
        {isMobile ? <ArticleSlide /> : <ArticleGrid />} 
      </main>
    </div>
  );
}

export default Home;
