import { IconAlertCircle } from '@tabler/icons-react';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { v4 as uuidv4 } from 'uuid';
import { apiUrl } from '../../routes/AdminRoutes';
import CommonBtn from './CommonBtn';
import CoverUploader from './CoverUploader';
import SpecManager from './SpecManager';
import TagManager from './TagManager';
import { deleteQiniuFile, uploadFileToQiniu } from './UploadQiniu';

const TranslateButton = ({cnVersion, onTranslationComplete}) => {
  const [isTranslating, setIsTranslating] = useState(false);
  const [translateError, setTranslateError] = useState('');

  const genEnVersion = async () => {
    setIsTranslating(true);
    setTranslateError('');
    try {
      const response = await fetch(`${apiUrl}/api/ptranslate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Session-ID': Cookies.get('sessionId'),
        },
        body: JSON.stringify(cnVersion),
      });

      if (!response.ok) {
        throw new Error('生成英文版本失败');
      }

      const englishVersion = await response.json();
      onTranslationComplete(englishVersion.data);
    } catch (error) {
      console.error('生成英文版本时出错:', error);
      setTranslateError('生成英文版本失败，请稍后重试');
    } finally {
      setIsTranslating(false);
    }
  };

  return (
    <>
      <CommonBtn
        onClick={genEnVersion}
        variant='primary'
        disabled={isTranslating}
        loading={isTranslating}
        loadingText='正在翻译中...'>
        从中文原文生成英文版本
      </CommonBtn>
      {translateError && (
        <div className='flex items-center mt-2 text-red-500'>
          <IconAlertCircle
            size={16}
            className='mr-1'
          />
          <span>{translateError}</span>
        </div>
      )}
    </>
  );
};

const ProductForm = ({product, onSave, onNewProduct}) => {
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!productData.title.trim()) newErrors.title = '产品名称不能为空';
    if (!productData.image) newErrors.image = '请上传产品图片';

    ['cn', 'en'].forEach((lang) => {
      if (!productData[lang].category.trim())
        newErrors[`${lang}_category`] = `${lang === 'cn' ? '中文' : '英文'}类别不能为空`;
      if (!productData[lang].description.trim())
        newErrors[`${lang}_description`] = `${lang === 'cn' ? '中文' : '英文'}描述不能为空`;
      if (productData[lang].spec.length === 0)
        newErrors[`${lang}_spec`] = `${lang === 'cn' ? '中文' : '英文'}规格不能为空`;
      if (productData[lang].package.length === 0)
        newErrors[`${lang}_package`] = `${lang === 'cn' ? '中文' : '英文'}优势不能为空`;
    });

    // 只检查中文版本的应用
    if (productData.cn.applications.length === 0) newErrors.applications = '应用不能为空';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [productData, setProductData] = useState({
    id: '',
    title: '',
    image: '',
    cn: {
      description: '',
      category: '',
      spec: [],
      applications: [],
      alike: [],
      package: [],
    },
    en: {
      description: '',
      category: '',
      spec: [],
      alike: [],
      package: [],
    },
    files: {
      manual: '',
      specSheet: '',
    },
  });

  const [saveStatus, setSaveStatus] = useState('');

  useEffect(() => {
    if (product) {
      setProductData({
        ...product,
        id: product.id || uuidv4(), // 如果product没有id，生成一个新的
      });
    } else {
      resetForm();
    }
  }, [product]);

  const resetForm = () => {
    setProductData({
      id: uuidv4(), // 为新产品生成一个 UUID
      title: '',
      image: '',
      cn: {
        description: '',
        category: '',
        spec: [],
        applications: [],
        alike: [],
        package: [],
      },
      en: {
        description: '',
        category: '',
        spec: [],
        alike: [],
        package: [],
      },
      files: {manual: '', specSheet: ''},
    });
  };

  const handleInputChange = (lang, field, value) => {
    setProductData((prev) => ({
      ...prev,
      [lang]: {...prev[lang], [field]: value},
    }));
  };

  const handleSave = async () => {
    if (!validateForm()) {
      setSaveStatus('error');
      return;
    }

    const saveData = {
      ...productData,
      id: productData.id || uuidv4(),
    };

    const result = await onSave(saveData);

    if (result.success) {
      setSaveStatus('success');
      // console.log(result.message);
    } else {
      setSaveStatus('error');
      console.error(result.message);
    }

    setTimeout(() => setSaveStatus(''), 3000);
  };

  const handleTranslationComplete = (englishVersion) => {
    setProductData((prev) => ({
      ...prev,
      en: {
        ...englishVersion,
        applications: prev.cn.applications, // 保持英文版本的应用与中文版本一致
      },
    }));
  };

  return (
    <div className='flex flex-col w-full h-full overflow-auto'>
      {Object.keys(errors).length > 0 && (
        <div className='p-4 mb-4 text-red-700 bg-red-100 rounded-md'>
          <h3 className='mb-2 font-bold'>请修正以下错误:</h3>
          <ul className='list-disc list-inside'>
            {Object.values(errors).map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}

      <div className='flex mb-6 space-x-6'>
        <div className='w-[250px]'>
          <CoverUploader
            image={productData.image}
            setImage={(url) => setProductData((prev) => ({...prev, image: url}))}
            articleId={productData.id}
            aspectRatio='1:1'
            width={250}
            height={250}
            format='JPG'
          />
        </div>
        <div className='flex flex-col justify-between w-2/3'>
          <div className='space-y-4'>
            <input
              className='w-full px-3 py-2 rounded-md outline outline-1 outline-neutral-400 focus:outline-2 focus:outline-blue-500 outline-offset-[-1px]'
              value={productData.title}
              onChange={(e) => setProductData((prev) => ({...prev, title: e.target.value}))}
              placeholder='产品名称'
            />
            <div className='flex flex-row gap-4'>
              <FileUploader
                fileType='manual'
                currentUrl={productData.files.manual}
                onUploadComplete={(url) =>
                  setProductData((prev) => ({...prev, files: {...prev.files, manual: url}}))
                }
                productId={productData.id}
              />
              <FileUploader
                fileType='specSheet'
                currentUrl={productData.files.specSheet}
                onUploadComplete={(url) =>
                  setProductData((prev) => ({...prev, files: {...prev.files, specSheet: url}}))
                }
                productId={productData.id}
              />
            </div>
            <ApplicationSelector
                selectedApplications={productData.cn.applications}
                onChange={(newApplications) =>
                  handleInputChange('cn', 'applications', newApplications)
                }
              />
            <div className='flex space-x-2'>
              <CommonBtn
                onClick={handleSave}
                variant={
                  saveStatus === 'success'
                    ? 'success'
                    : saveStatus === 'error'
                    ? 'danger'
                    : 'primary'
                }>
                {saveStatus === 'success'
                  ? '保存成功'
                  : saveStatus === 'error'
                  ? '保存失败'
                  : '保存'}
              </CommonBtn>
              <CommonBtn
                onClick={onNewProduct}
                variant='secondary'>
                新建
              </CommonBtn>
              <TranslateButton
                cnVersion={productData.cn}
                onTranslationComplete={handleTranslationComplete}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='grid grid-cols-2 gap-6'>
        {['cn', 'en'].map((lang) => (
          <div
            key={lang}
            className='space-y-4'>
            <h3 className='p-2 text-lg font-semibold text-left rounded-lg bg-cyan-700/30'>
              {lang === 'cn' ? '中文版本' : '英文版本'}
            </h3>
            <input
              className='w-full px-3 py-2 rounded-md outline outline-1 outline-neutral-400 focus:outline-2 focus:outline-blue-500 outline-offset-[-2px]'
              value={productData[lang].category}
              onChange={(e) => handleInputChange(lang, 'category', e.target.value)}
              placeholder='类别'
            />
            <TextareaAutosize
              className='w-full px-3 py-2 rounded-md outline outline-1 outline-neutral-400 focus:outline-2 focus:outline-blue-500 outline-offset-[-2px] resize-none'
              value={productData[lang].description}
              onChange={(e) => handleInputChange(lang, 'description', e.target.value)}
              placeholder='描述'
              minRows={4}
            />
            <SpecManager
              specs={productData[lang].spec}
              onChange={(newSpecs) => handleInputChange(lang, 'spec', newSpecs)}
            />
            <TagManager
              tags={productData[lang].alike}
              setTags={(newTags) => handleInputChange(lang, 'alike', newTags)}
              placeholder='对标产品'
            />
            <TagManager
              tags={productData[lang].package}
              setTags={(newTags) => handleInputChange(lang, 'package', newTags)}
              placeholder='包装信息'
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductForm;

const ApplicationSelector = ({selectedApplications, onChange}) => {
  const applicationOptions = [
    {label: '色母粒', value: 'A'},
    {label: '热熔制品', value: 'B'},
    {label: '电缆料', value: 'C'},
    {label: '油墨', value: 'D'},
    {label: '橡胶', value: 'E'},
    {label: 'PVC管材', value: 'F'},
    {label: '防水涂料', value: 'G'},
  ];

  const handleChange = (value) => {
    if (value === 'ALL') {
      onChange(
        selectedApplications.length === applicationOptions.length
          ? []
          : applicationOptions.map((opt) => opt.value)
      );
    } else {
      const updatedApplications = selectedApplications.includes(value)
        ? selectedApplications.filter((app) => app !== value)
        : [...selectedApplications, value];
      onChange(updatedApplications);
    }
  };

  const allSelected = selectedApplications.length === applicationOptions.length;

  return (
    <div className='flex flex-row space-x-2'>
      <label className='block text-lg font-medium text-left text-neutral-700'>应用</label>
      <div className='flex flex-wrap gap-2'>
        <label className='inline-flex items-center'>
          <input
            type='checkbox'
            className='w-5 h-5 text-blue-600 form-checkbox'
            checked={allSelected}
            onChange={() => handleChange('ALL')}
          />
          <span className='ml-2 font-bold text-blue-700'>全部</span>
        </label>
        {applicationOptions.map((option) => (
          <label
            key={option.value}
            className='inline-flex items-center'>
            <input
              type='checkbox'
              className='w-5 h-5 text-blue-600 form-checkbox'
              checked={selectedApplications.includes(option.value)}
              onChange={() => handleChange(option.value)}
            />
            <span className='ml-2 text-neutral-700'>{option.label}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

const FileUploader = ({fileType, currentUrl, onUploadComplete}) => {
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setUploading(true);
    setUploadStatus(null);

    try {
      // 如果存在当前文件，先删除它
      if (currentUrl) {
        const fileKey = currentUrl.split('/').pop(); // 从URL中提取文件名作为key
        await deleteQiniuFile(fileKey);
        // console.log('旧文件删除成功');
      }

      // 上传新文件
      const url = await uploadFileToQiniu(file, (progress) => {
        // console.log(`上传进度: ${progress}%`);
      });
      onUploadComplete(url);
      setUploadStatus('success');
    } catch (error) {
      console.error('文件操作失败:', error);
      setUploadStatus('error');
    } finally {
      setUploading(false);
    }
  };

  const getButtonText = () => {
    if (uploading) return '上传中...';
    if (uploadStatus === 'success' || currentUrl) return '重新上传';
    if (uploadStatus === 'error') return '上传失败';
    return '上传';
  };

  const getButtonClasses = () => {
    let classes =
      'px-4 py-2 rounded-lg hover:opacity-90 whitespace-nowrap font-bold transition-colors duration-200 text-white cursor-pointer ';
    if (uploading) return classes + 'bg-yellow-500 hover:bg-yellow-600';
    if (uploadStatus === 'success' || currentUrl) return classes + 'bg-blue-500 hover:bg-blue-600';
    if (uploadStatus === 'error') return classes + 'bg-red-500 hover:bg-red-600';
    return classes + 'bg-gray-500 hover:bg-gray-600';
  };

  return (
    <div className='flex items-center '>
      <input
        type='file'
        onChange={handleFileChange}
        className='hidden'
        id={`file-upload-${fileType}`}
        accept='.pdf,.xlsx,.xls'
      />
      <label htmlFor={`file-upload-${fileType}`}>
        <span className={getButtonClasses()}>
          {getButtonText()}
          {fileType === 'manual' ? '说明书' : '规格表'}
        </span>
      </label>
      {currentUrl && !uploading && (
        <a
          href={currentUrl}
          target='_blank'
          rel='noopener noreferrer'
          className='text-blue-500 hover:underline'>
          查看文件
        </a>
      )}
    </div>
  );
};