import React, {useEffect, useState} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import Pagination from './Pagination';
import {apiUrl} from '../routes/AdminRoutes';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
const itemsPerPage = 3;

function ArticleGrid() {
  const {i18n} = useTranslation();
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedArticles, setDisplayedArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const currentLanguage = i18n.language;
        const apiLanguage = currentLanguage === 'zh' ? 'cn' : 'en';
        const response = await fetch(`${apiUrl}/banner/news`, {
          headers: {
            'X-Custom-Language': apiLanguage,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setArticles(data);
      } catch (error) {
        console.error('获取文章数据失败:', error);
      }
    };

    fetchArticles();
  }, [i18n.language]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setDisplayedArticles(articles.slice(startIndex, endIndex));
  }, [currentPage, articles]);

  const totalPages = Math.ceil(articles.length / itemsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div className='mx-auto bg-white max-w-7xl article-grid'>
      <AnimatePresence mode='wait' initial={false}>
        <motion.div
          key={currentPage}
          initial={{opacity: 0, x: 300}}
          animate={{opacity: 1, x: 0}}
          exit={{opacity: 0, x: -300}}
          transition={{duration: 0.5}}
          className='grid grid-cols-1 md:grid-cols-3'>
          {displayedArticles.map((article, index) => (
            <motion.div
              key={`${currentPage.value}-${index}`}
              initial={{opacity: 0, y: 50}}
              animate={{opacity: 1, y: 0}}
              transition={{delay: index * 0.1}}
              className={`flex flex-col ${index === 1 ? 'flex-col-reverse' : ''} group relative`}>
              <div className={`relative h-64 overflow-hidden ${index === 1 ? 'flex flex-col-reverse' : ''}`}>
                <img
                  src={article.image}
                  alt={article.title}
                  className='object-cover w-full h-full transition-transform duration-500 group-hover:scale-105'
                />
                <div className='absolute inset-0 transition-all duration-500 opacity-0 bg-cyan-900 group-hover:opacity-70'>
                  <div className='w-0 h-full transition-all duration-500 bg-cyan-800 group-hover:w-full'></div>
                </div>
                <div className='absolute inset-0 flex items-center justify-center transition-opacity duration-500 opacity-0 group-hover:opacity-100'>
                  <Link
                    to={`/news/${article.id}`}
                    state={{news: article}}
                    className='px-4 py-2 font-semibold transition-all duration-300 bg-white rounded-lg select-none text-neutral-900 hover:bg-neutral-100 hover:text-cyan-700 hover:shadow-md'>
                    了解更多 &gt;
                  </Link>
                </div>
              </div>
              <div className='flex flex-col justify-between flex-grow p-6 transition-colors duration-500 bg-white text-neutral-900 group-hover:bg-neutral-800'>
                <div className='text-left'>
                  <div className='flex flex-wrap mb-2'>
                    {article.tags.map((tag, index) => (
                      <span
                        key={index}
                        className='mb-1 mr-2 text-sm font-semibold transition-colors duration-500 text-cyan-700 group-hover:text-cyan-400'>
                        {tag}
                        {index < article.tags.length - 1 && ','}
                      </span>
                    ))}
                  </div>
                  <h2 className='mb-4 text-xl font-bold transition-colors duration-500 group-hover:text-white'>{article.title}</h2>
                </div>
              </div>
              <div
                className={`absolute ${
                  index === 1 ? 'top-0' : 'bottom-0'
                } left-0 w-0 h-1 bg-cyan-700 transition-all duration-500 group-hover:w-full`}></div>
            </motion.div>
          ))}
        </motion.div>
      </AnimatePresence>
      <Pagination currentPage={currentPage} totalPages={totalPages} onPrevPage={prevPage} onNextPage={nextPage} />
    </div>
  );
}

export default ArticleGrid;
