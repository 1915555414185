import React from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Home from '../pages/Home';
import ProductSearch from '../pages/ProductSearch';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import NewsAndEvents from '../pages/NewsAndEvents';
import NewsPage from '../components/NewsPage';
import About from '../pages/About';
import Contact from '../pages/Contact';
import ProductDetail from '../pages/ProductDetail';
import Breadcrumb from '../components/Breadcrumb';

function PublicRoutes() {
  const {t} = useTranslation();
  const location = useLocation();


  const getBreadcrumbItems = () => {
    const path = location.pathname;
    const items = [];

    if (path.startsWith('/products')) {
      items.push({label: 'navbar.products', link: '/products'});
      if (path !== '/products') {
        items.push({label: 'Product Detail'});
      }
    } else if (path.startsWith('/news')) {
      items.push({label: 'navbar.news_and_events', link: '/news'});
      if (path !== '/news') {
        const newsTitle = location.state?.news?.title || t('newsDetail');
        items.push({label: newsTitle});
      }
    } else if (path === '/about') {
      items.push({label: 'navbar.about'});
    } else if (path === '/contact') {
      items.push({label: 'navbar.contact'});
    }

    return items;
  };

  const showBreadcrumb = location.pathname !== '/';

  return (
    <div className='flex flex-col w-full min-h-screen public-area'>
      <Navbar />
      {showBreadcrumb && <Breadcrumb items={getBreadcrumbItems()} />}
      <main className='flex-grow w-full'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/products' element={<ProductSearch />} />
          <Route path='/products/:id' element={<ProductDetail />} />
          <Route path='/about' element={<About />} />
          <Route path='/news' element={<NewsAndEvents />} />
          <Route path='/news/:id' element={<NewsPage />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default PublicRoutes;
