import React, {useState} from 'react';

const CommonBtn = ({
  onClick,
  children,
  disabled = false,
  loading = false,
  loadingText = '加载中...',
  icon: Icon,
  variant = 'primary',
  size = 'medium',
}) => {
  const [isLoading, setIsLoading] = useState(loading);

  const handleClick = async (e) => {
    if (disabled || isLoading) return;

    setIsLoading(true);
    try {
      await onClick(e);
    } finally {
      setIsLoading(false);
    }
  };

  const baseClasses = 'px-4 py-2 rounded-lg hover:opacity-90 whitespace-nowrap font-bold transition-colors duration-200';

  const variantClasses = {
    primary: 'bg-blue-500 hover:bg-blue-600 text-white',
    secondary: 'bg-gray-500 hover:bg-gray-600 text-white',
    success: 'bg-green-500 hover:bg-green-600 text-white',
    danger: 'bg-red-500 hover:bg-red-600 text-white',
    warning: 'bg-yellow-500 hover:bg-yellow-600 text-white',
  };

  const sizeClasses = {
    small: 'text-sm px-2 py-1',
    medium: 'text-base px-4 py-2',
    large: 'text-lg px-6 py-3',
  };

  const combinedClasses = `${baseClasses} ${variantClasses[variant]} ${sizeClasses[size]}`;

  return (
    <button onClick={handleClick} className={combinedClasses} disabled={disabled || isLoading}>
      {isLoading ? (
        <>
          <span className='mr-2'>{loadingText}</span>
          <span className='animate-spin'>&#9696;</span>
        </>
      ) : (
        <>
          {Icon && <Icon size={size === 'small' ? 14 : size === 'large' ? 20 : 16} className='mr-2' />}
          {children}
        </>
      )}
    </button>
  );
};

export default CommonBtn;
