import React, {useState} from 'react';
import {IconX} from '@tabler/icons-react';
import CommonBtn from './CommonBtn';

const TagManager = ({tags, setTags, placeholder = '输入新标签'}) => {
  const [currentTag, setCurrentTag] = useState('');

  const addTag = () => {
    if (currentTag && !tags.includes(currentTag)) {
      setTags([...tags, currentTag]);
      setCurrentTag('');
    }
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  return (
    <div className='flex items-center flex-wrap overflow-x-auto min-h-[40px] p-2 rounded-md outline outline-1 outline-neutral-400 outline-offset-[-1px]'>
      {tags.map((tag) => (
        <span key={tag} className='flex items-center h-8 px-2 m-1 text-sm rounded text-neutral-700 bg-neutral-100 whitespace-nowrap'>
          {tag}
          <button onClick={() => removeTag(tag)} className='ml-1 text-neutral-500 hover:text-neutral-700'>
            <IconX size={14} />
          </button>
        </span>
      ))}
      <input
        type='text'
        value={currentTag}
        onChange={(e) => setCurrentTag(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && addTag()}
        className='flex-grow h-8 px-2 m-1 text-sm rounded outline-none focus:outline-none placeholder-neutral-500'
        placeholder={placeholder}
      />
      <CommonBtn onClick={addTag} variant='secondary' size='small' className='m-1'>
        添加
      </CommonBtn>
    </div>
  );
};

export default TagManager;