import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const isProduction = process.env.NODE_ENV === 'production';
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3000';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'zh'],
    debug: !isProduction,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${apiUrl}/locales/{{lng}}/{{ns}}.json`,
    },
    detection: {
      order: ['navigator', 'querystring', 'cookie'],
      caches: ['cookie'],
    },
  });

export default i18n;