import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import './App.css';
import AppRouter from './routes/AppRouter';

function App() {
  const {i18n} = useTranslation();

  useEffect(() => {
    // 确保初始语言设置正确
    const detectedLang = i18n.language;
    if (detectedLang !== i18n.language) {
      i18n.changeLanguage(detectedLang);
    }
  }, [i18n]);

  return (
    <div className='App'>
      <AppRouter />
    </div>
  );
}

export default App;
