import React, { useState, useEffect, useRef } from 'react';

const AnimatedNumber = ({ value, duration = 2000, decimals = 1, suffix = '' }) => {
  const [animatedValue, setAnimatedValue] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    const interval = 50;
    const steps = duration / interval;
    let currentStep = 0;

    const timer = setInterval(() => {
      currentStep++;
      const progress = currentStep / steps;
      const newValue = Math.min(progress * value, value);
      setAnimatedValue(newValue);

      if (currentStep === steps) {
        clearInterval(timer);
      }
    }, interval);

    return () => clearInterval(timer);
  }, [isVisible, value, duration]);

  return (
    <span ref={ref}>
      {animatedValue.toFixed(decimals)}
      {suffix}
    </span>
  );
};

export default AnimatedNumber;