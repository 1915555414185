import React, {useRef, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import ChatBox from './ChatBox';

function HeroSlider() {
  const {t} = useTranslation();
  const videoRef = useRef(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const setHeight = () => {
      const vh = window.innerHeight;
      containerRef.current.style.height = `${vh}px`;
    };
    setHeight();
    window.addEventListener('resize', setHeight);

    return () => {
      window.removeEventListener('resize', setHeight);
    };
  }, []);

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  return (
    <div
      ref={containerRef}
      className='relative w-full overflow-hidden'>
      <video
        ref={videoRef}
        className='absolute object-cover w-full h-full'
        autoPlay={false}
        loop
        muted
        playsInline
        onLoadedData={handleVideoLoad}>
        <source
          src='https://cdn.tailiwax.com/profile/TailiV.webm'
          type='video/webm'
        />
        {t('Your browser does not support the video tag.')}
      </video>
      <div className='absolute w-full h-full bg-neutral-900/50 backdrop-blur-sm'></div>
      <div className='relative top-0 left-0 z-30 flex flex-col items-center justify-center w-full h-full pb-[0vh]'>
        <ChatBox isVideoLoaded={isVideoLoaded} videoRef={videoRef} />
        <div className='absolute top-0 left-0 z-20 w-full mix-blend-multiply h-1/3 bg-gradient-to-b from-neutral-800 to-transparent'></div>
      </div>
    </div>
  );
}

export default HeroSlider;
