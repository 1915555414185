import Cookies from 'js-cookie';
import React, {useEffect, useState} from 'react';
import {Link, Navigate, Outlet, Route, Routes, useNavigate} from 'react-router-dom';
import NewsEditor from '../components/admin/NewsEditor';
import ProductEditor from '../components/admin/ProductEditor'; // 导入 ProductEditor 组件

// import Dashboard from '../pages/admin/Dashboard';
// import Articles from '../pages/admin/Articles';
// ... 其他管理页面
// export const apiUrl = process.env.REACT_APP_API_URL || 'http://192.168.1.2:8001'
export const apiUrl = process.env.REACT_APP_API_URL 

function Dashboard() {
  return <h2>欢迎光临</h2>; // 临时的 Dashboard 组件
}

function AdminRoutes() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [sessionId, setSessionId] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (userData) => {
    setIsAuthenticated(true);
    setShowLoginForm(false);
    setSessionId(userData.session_id);
    Cookies.set('sessionId', userData.session_id, {expires: 3}); // 保存 cookie，有效期 3 天
  };

  useEffect(() => {
    const storedSessionId = Cookies.get('sessionId');
    if (storedSessionId) {
      setIsAuthenticated(true);
      setShowLoginForm(false);
      setSessionId(storedSessionId);
    }
  }, []);

  const handleLogout = () => {
    setIsAuthenticated(false);
    setShowLoginForm(true);
    setSessionId('');
    Cookies.remove('sessionId');
    navigate('/admin');
  };

  if (!isAuthenticated) {
    return <LoginForm onLogin={handleLogin} show={showLoginForm} />;
  }

  return (
    <div className='admin-area'>
      <nav className='p-4 text-white bg-neutral-800'>
        <ul className='flex space-x-4'>
          <li>
            <Link to='/admin/article-editor' className='hover:text-cyan-300'>
              新闻编辑
            </Link>
          </li>
          <li>
            <Link to='/admin/product-editor' className='hover:text-cyan-300'>
              产品编辑
            </Link>
          </li>
          <li>
            <button onClick={handleLogout} className='hover:text-cyan-300'>
              登出
            </button>
          </li>
        </ul>
      </nav>
      <div className='flex items-center w-full h-full mx-auto max-w-7xl'>
        <Routes>
          <Route element={<ProtectedRoute sessionId={sessionId} />}>
            <Route path='/' element={<Dashboard />} />
            <Route path='article-editor' element={<NewsEditor />} />
            <Route path='product-editor' element={<ProductEditor />} /> {/* 使用 ProductEditor 组件 */}
          </Route>
          <Route path='*' element={<Navigate to='/admin' replace />} />
        </Routes>
      </div>
    </div>
  );
}

export default AdminRoutes;

function ProtectedRoute({sessionId, children}) {
  const navigate = useNavigate();

  useEffect(() => {
    const verifySession = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/verify-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({session_id: sessionId}),
        });

        if (!response.ok) {
          throw new Error('Session verification failed');
        }
      } catch (error) {
        console.error('Session verification error:', error);
        navigate('/admin');
      }
    };

    if (sessionId) {
      verifySession();
    } else {
      navigate('/admin');
    }
  }, [sessionId, navigate]);

  return sessionId ? <Outlet /> : null;
}

function LoginForm({onLogin, show}) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    if (!show) {
      const timer = setTimeout(() => setOpacity(0), 100);
      return () => clearTimeout(timer);
    }
  }, [show]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch(`${apiUrl}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({username, password}),
      });

      if (response.ok) {
        const data = await response.json();
        onLogin(data);
      } else {
        const errorData = await response.json();
        setError(errorData.message || '登录失败，请检查用户名和密码');
      }
    } catch (error) {
      setError('网络错误，请稍后重试');
    }
  };

  return (
    <div
      className='flex items-center justify-center min-h-screen'
      style={{
        opacity: opacity,
        transition: 'opacity 0.5s ease-out',
        display: opacity === 0 ? 'none' : 'flex',
      }}>
      <form onSubmit={handleSubmit} className='p-8 bg-white rounded-lg w-96'>
        <h2 className='mb-6 text-2xl font-semibold text-neutral-800'>登录泰利管理后台</h2>
        {error && <p className='mb-4 text-red-500'>{error}</p>}
        <div className='mb-4'>
          <input
            type='text'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder='用户名'
            className='w-full px-3 py-2 rounded-md outline outline-1 outline-neutral-300 focus:outline-2 focus:outline-neutral-500'
          />
        </div>
        <div className='mb-6'>
          <input
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder='密码'
            className='w-full px-3 py-2 rounded-md outline outline-1 outline-neutral-300 focus:outline-2 focus:outline-neutral-500'
          />
        </div>
        <button
          type='submit'
          className='w-full px-4 py-2 font-bold text-white transition duration-300 rounded-md shadow-md bg-cyan-500 hover:bg-cyan-600'>
          登录
        </button>
      </form>
    </div>
  );
}
