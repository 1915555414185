import React, {useState, useEffect} from 'react';
import ProductList from './ProductList';
import ProductForm from './ProductForm';
import {apiUrl} from '../../routes/AdminRoutes';
import Cookies from 'js-cookie';

const ProductEditor = () => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchProducts = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/productlist`);
      if (!response.ok) {
        throw new Error('获取产品列表失败');
      }
      const data = await response.json();
      // console.log('fetchProducts', data);
      
      // 根据 title 对产品进行排序
      const sortedProducts = data.sort((a, b) => a.title - b.title);
      
      setProducts(sortedProducts);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleEdit = async (product) => {
    try {
      const response = await fetch(`${apiUrl}/api/product/${product.id}`);
      if (!response.ok) {
        throw new Error('获取产品详情失败');
      }
      const productDetails = await response.json();
      // console.log('productDetails', productDetails);
      setSelectedProduct(productDetails);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${apiUrl}/api/product/${id}`, {
        method: 'DELETE',
        headers: {
          'X-Session-ID': Cookies.get('sessionId'),
        },
      });
      if (!response.ok) {
        throw new Error('删除产品失败');
      }
      await response.json();
      // 直接从状态中删除产品
      setProducts((prevProducts) => prevProducts.filter((product) => product.id !== id));
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSave = async (productData) => {
    try {
      const response = await fetch(`${apiUrl}/api/product/${productData.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-Session-ID': Cookies.get('sessionId'),
        },
        body: JSON.stringify(productData),
      });
      if (!response.ok) {
        throw new Error('保存产品失败');
      }
      const result = await response.json();

      // 直接更新状态中的产品
      setProducts((prevProducts) => {
        const index = prevProducts.findIndex((p) => p.id === productData.id);
        if (index !== -1) {
          // 更新现有产品
          const updatedProducts = [...prevProducts];
          updatedProducts[index] = {...updatedProducts[index], ...productData};
          return updatedProducts;
        } else {
          // 添加新产品
          return [...prevProducts, productData];
        }
      });

      return {success: true, message: result.message};
    } catch (err) {
      setError(err.message);
      return {success: false, message: err.message};
    }
  };

  const handleNewProduct = () => {
    setSelectedProduct(null);
  };

  return (
    <div className='flex w-full h-full gap-6 py-6'>
      {loading ? (
        <div>加载中...</div>
      ) : error ? (
        <div>错误：{error}</div>
      ) : (
        <ProductList products={products} onEdit={handleEdit} onDelete={handleDelete} />
      )}
      <ProductForm product={selectedProduct} onNewProduct={handleNewProduct} onSave={handleSave} />
    </div>
  );
};

export default ProductEditor;
