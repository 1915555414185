import React, {useEffect, useMemo, useRef, useState} from 'react';
import CustomQuill from './CustomQuill';
import {deleteQiniuFile, uploadToQiniu} from './UploadQiniu';
import 'react-quill/dist/quill.snow.css';

const ContentEditor = ({content, setContent, articleId}) => {
  const quillRef = useRef(null);
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const initialImageUrls = extractImageUrls(content);
    setImageUrls(initialImageUrls);
  }, []);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{header: [1, 2, 3, false]}],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
          ['link', 'image', 'video'],
          ['clean'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  function imageHandler() {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        try {
          const imageUrl = await uploadToQiniu(
            file,
            (progress) => {
              // console.log('上传进度：', progress);
              // 这里可以添加上传进度的显示逻辑
            },
            articleId
          );
          if (quillRef.current) {
            const quill = quillRef.current.getQuill();
            const range = quill.getSelection(true);
            quill.insertEmbed(range.index, 'image', imageUrl);
          }
        } catch (error) {
          console.error('图片上传失败', error);
          // 这里可以添加错误提示的显示逻辑
        }
      }
    };
  }

  const handleContentChange = (newContent) => {
    setContent(newContent);

    // 提取新内容中的所有图片URL
    const newImageUrls = extractImageUrls(newContent);

    // 找出被删除的图片URL
    const deletedUrls = imageUrls.filter((url) => !newImageUrls.includes(url));

    // 对每个被删除的图片URL调用deleteQiniuFile
    deletedUrls.forEach(async (url) => {
      try {
        const fileKey = url.split('/').pop(); // 从URL中提取文件名作为key
        await deleteQiniuFile(fileKey);
        // console.log('图片删除成功:', url);
      } catch (error) {
        console.error('图片删除失败:', url, error);
      }
    });

    // 更新imageUrls状态
    setImageUrls(newImageUrls);
  };

  // 辅助函数：从内容中提取所有图片URL
  const extractImageUrls = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const images = doc.getElementsByTagName('img');
    return Array.from(images).map((img) => img.src);
  };

  return (
    <div className='flex flex-col h-full'>
      <label className='block mb-1 text-sm font-medium text-gray-700 '>文章内容</label>
      <CustomQuill
        ref={quillRef}
        value={content}
        onChange={handleContentChange}
        modules={modules}
      />
    </div>
  );
};

export default ContentEditor;
