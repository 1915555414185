import React, {useState, useEffect} from 'react';
import Cookies from 'js-cookie';
import ArticleList from './ArticleList';
import ArticleForm from './ArticleForm';
import {apiUrl} from '../../routes/AdminRoutes';
// 添加一个辅助函数来格式化日期
const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const NewsEditor = () => {
  const [articles, setArticles] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/articlelist`);
        if (!response.ok) {
          throw new Error('获取文章列表失败');
        }
        const data = await response.json();
        const formattedArticles = data.map((article) => ({
          ...article,
          status: article.published ? 'published' : 'draft',
          date: article.date,
        }));
        setArticles(formattedArticles);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  const handleEdit = async (article) => {
    try {
      const response = await fetch(`${apiUrl}/api/article/${article.id}`);
      if (!response.ok) {
        throw new Error('获取文章详情失败');
      }
      const articleDetails = await response.json();
      // console.log(articleDetails)
      setSelectedArticle(articleDetails);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${apiUrl}/api/article/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-Session-ID': Cookies.get('sessionId'),
        },
      });
      if (!response.ok) {
        throw new Error('删除文章失败');
      }
      await response.json();
      setArticles(articles.filter((article) => article.id !== id));
      if (selectedArticle && selectedArticle.id === id) {
        setSelectedArticle(null);
      }
    } catch (err) {
      console.error('删除文章时出错:', err);
      setError(err.message);
    }
  };

  const handlePublish = async (article) => {
    try {
      const response = await fetch(`${apiUrl}/api/publish`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Session-ID': Cookies.get('sessionId'),
        },
        body: JSON.stringify({id: article.id}),
      });

      if (!response.ok) {
        throw new Error('发布文章失败');
      }

      const result = await response.json();
      // console.log('文章发布成功:', result);

      setArticles((prevArticles) =>
        prevArticles.map((a) =>
          a.id === article.id ? {...a, status: 'published', published: true} : a
        )
      );

      if (selectedArticle && selectedArticle.id === article.id) {
        setSelectedArticle({...selectedArticle, status: 'published', published: true});
      }

      return {success: true, message: '文章已发布'};
    } catch (err) {
      console.error('发布文章时出错:', err);
      setError(err.message);
      return {success: false, message: err.message};
    }
  };

  const handleSave = async (articleData, isPublish = false) => {
    try {
      const response = await fetch(`${apiUrl}/api/draft`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Session-ID': Cookies.get('sessionId'),
        },
        body: JSON.stringify(articleData),
      });

      if (!response.ok) {
        throw new Error('保存文章失败');
      }

      const result = await response.json();
      // console.log('文章提交成功:', result);

      const updatedArticle = {
        ...articleData,
        id: result.article_id,
        status: isPublish ? 'published' : 'draft',
        date: formatDate(new Date()),
      };

      setArticles((prevArticles) => {
        const index = prevArticles.findIndex((a) => a.id === updatedArticle.id);
        if (index !== -1) {
          return prevArticles.map((a) => (a.id === updatedArticle.id ? updatedArticle : a));
        } else {
          return [...prevArticles, updatedArticle];
        }
      });

      setSelectedArticle(updatedArticle);

      return {success: true, message: isPublish ? '文章已发布' : '草稿已保存'};
    } catch (err) {
      console.error('保存文章时出错:', err);
      setError(err.message);
      return {success: false, message: err.message};
    }
  };

  const handleNewArticle = () => {
    setSelectedArticle(null);
  };

  return (
    <div className='flex w-full h-full'>
      {loading ? (
        <div>加载中...</div>
      ) : error ? (
        <div>错误：{error}</div>
      ) : (
        <ArticleList
          articles={articles}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onPublish={handlePublish}
        />
      )}
      <ArticleForm
        article={selectedArticle}
        onNewArticle={handleNewArticle}
        onSave={handleSave}
      />
    </div>
  );
};

export default NewsEditor;
