import React from 'react';
import Tag from './Tag';
import {Link} from 'react-router-dom';

const stripHtml = (html) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};
const NewsCard = ({news}) => {
  const plainTextContent = stripHtml(news.content);

  return (
    <Link
      to={`/news/${news.id}`}
      state={{ news }}
      className='block w-full p-4 sm:p-5 transition duration-200 rounded-lg outline outline-1 outline-offset-[-1px] outline-neutral-700/20 hover:bg-neutral-200'>
      <div className='w-full gap-6 md:flex'>
        <div className='overflow-hidden rounded-lg md:w-48 md:flex-shrink-0'>
          <div className='aspect-w-3 aspect-h-2'>
            <img className='object-cover w-full h-full' src={news.image} alt={news.title} />
          </div>
        </div>
        <div className='flex flex-col justify-between w-full pt-4 md:pt-0'>
          <div>
            <div className='flex flex-wrap items-center justify-between mb-3 md:flex-row'>
              <h2 className='text-lg font-semibold sm:text-xl text-neutral-700'>{news.title}</h2>
              <span className='mt-1 text-sm text-neutral-500 md:mt-0'>{news.date}</span>
            </div>
            <div className='flex flex-wrap w-full gap-2 mb-3'>
              {news.tags.map((tag, index) => (
                <Tag key={index} text={tag} className='bg-neutral-200 text-neutral-700' />
              ))}
            </div>
          </div>
          <p className='w-full mt-2 text-sm font-light prose-sm text-left text-neutral-800 line-clamp-1'>{plainTextContent}</p>
        </div>
      </div>
    </Link>
  );
};

export default NewsCard;
