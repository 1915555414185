import React, {useState, useRef, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import {
  IconFileTypeXls,
  IconFileTypePdf,
  IconDownload,
  IconPalette,
  IconFlame,
  IconRoute2,
  IconPaint,
  IconLeaf,
  IconTestPipe2Filled,
  IconDroplet,
} from '@tabler/icons-react';
import {motion, AnimatePresence} from 'framer-motion';
import {useTranslation} from 'react-i18next';

const FileButton = ({icon, label, fileUrl, className}) => (
  <a
    href={fileUrl}
    target='_blank'
    rel='noopener noreferrer'
    className={`flex flex-row items-center justify-between p-2 text-xs font-semibold rounded-md transition-colors duration-200 ${className}`}>
    <IconDownload />
    <span>{label}</span>
    <div className='flex items-center justify-center w-6 h-6 mr-1'>{icon}</div>
  </a>
);

const SpecItem = ({item, result, unit, temp, method}) => {
  const displayResult = () => {
    if (item === 'viscosity') {
      return `${result} ${unit}@${temp}°C`;
    }
    return `${result}${unit ? ` ${unit}` : ''}${temp ? `@${temp}°C` : ''}`;
  };

  return (
    <div className='flex flex-col justify-between p-2 text-left rounded-md bg-neutral-200 min-w-fit'>
      <div className='flex justify-between gap-2'>
        <p className='text-xs font-semibold text-neutral-700'>{item}</p>
        {method && <p className='text-xs text-neutral-500'>{method}</p>}
      </div>
      <div className='flex items-end justify-between'>
        <p className='text-xs font-bold text-cyan-600'>{displayResult()}</p>
      </div>
    </div>
  );
};

const MobileProductCard = ({product, toggleExpand, isExpanded}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleLearnMore = (e) => {
    e.stopPropagation();
    navigate('/contact');
  };

  return (
    <div
      className='flex flex-col gap-4 p-2 transition-all duration-100 border rounded-lg shadow-md'
      onClick={toggleExpand}>
      <div className='flex flex-col items-start gap-4 '>
        <div className='w-full p-1 px-2 text-sm font-bold text-center rounded-lg text-neutral-100 bg-neutral-600'>
          {product.category}
        </div>
        <div className='flex flex-row items-start w-full gap-2'>
          <div className='overflow-hidden rounded-lg shrink-0 w-28 h-28'>
            <img
              src={product.image}
              alt={product.title}
              className='object-cover w-full h-full'
            />
          </div>
          <div className='flex flex-col items-start justify-between w-full gap-2 h-28'>
            <h3 className='flex-grow text-3xl font-bold text-left whitespace-nowrap'>
              {product.title}
            </h3>
            {!isExpanded ? (
              <p className='text-sm font-normal text-left opacity-90 h-fit line-clamp-3'>
                {product.description}
              </p>
            ) : (
              <button
                onClick={handleLearnMore}
                className='flex-grow w-full px-4 py-2 font-bold text-white transition-colors duration-200 rounded-md h-fit bg-cyan-600 hover:bg-cyan-700'>
                {t('tag.get_sample')}
              </button>
            )}
          </div>
        </div>
      </div>
      <AnimatePresence initial={false}>
        {isExpanded && (
          <motion.div
            initial='collapsed'
            animate='expanded'
            exit='collapsed'
            variants={{
              expanded: {opacity: 1, height: 'auto'},
              collapsed: {opacity: 0, height: 0},
            }}
            key={product.id}
            transition={{duration: 0.2, ease: 'easeInOut'}}
            className='flex flex-col gap-4'>
            <div className='grid grid-cols-2 gap-1'>
              {product.spec.map((spec, index) => (
                <SpecItem
                  key={index}
                  {...spec}
                />
              ))}
              {product.files && product.files.manual && (
                <FileButton
                  icon={
                    <IconFileTypePdf
                      size={24}
                      stroke={1.5}
                    />
                  }
                  label='产品手册'
                  fileUrl={product.files.manual}
                  className='font-bold bg-cyan-700/30 text-neutral-800 hover:bg-cyan-700 hover:text-neutral-100 hover:shadow-md'
                />
              )}
              {product.files && product.files.specSheet && (
                <FileButton
                  icon={
                    <IconFileTypeXls
                      size={24}
                      stroke={1.5}
                    />
                  }
                  label='技术数据表'
                  fileUrl={product.files.specSheet}
                  className='font-bold bg-cyan-700/30 text-neutral-800 hover:bg-cyan-700 hover:text-neutral-100 hover:shadow-md'
                />
              )}
            </div>
            <p className='px-1 text-sm font-normal text-left opacity-90 '>{product.description}</p>
            {product.alike && product.alike.length > 0 && (
              <p className='p-2 px-1 text-sm font-normal text-left h-fit opacity-70 line-clamp-2'>
                {t('tag.alike')}:
                {product.alike.map((detail, index) => (
                  <span key={index}>
                    {detail}
                    {index < product.alike.length - 1 ? ', ' : ''}
                  </span>
                ))}
              </p>
            )}
            {product.package &&
              product.package.length > 0 &&
              product.package.map((detail, index) => (
                <p className='p-2 px-1 text-sm font-normal text-left h-fit opacity-70 line-clamp-2'>
                  {t('tag.packaging')}:{detail}
                </p>
              ))}

            {product.applications && product.applications.length > 0 && (
              <div className='flex flex-wrap gap-1'>
                {product.applications.map((app, index) => (
                  <ApplicationTag
                    key={index}
                    application={app}
                  />
                ))}
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const DesktopProductCard = ({product, toggleExpand, isExpanded}) => {
  const contentRef = useRef(null);
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleLearnMore = (e) => {
    e.stopPropagation();
    navigate('/contact');
  };

  return (
    <div
      className='overflow-hidden transition-all duration-200 rounded-lg shadow-md cursor-pointer ring-1 ring-neutral-400 hover:shadow-md hover:ring-2 hover:ring-cyan-700'
      onClick={toggleExpand}>
      <div className='flex flex-col gap-4 p-4'>
        <div className='flex flex-row gap-4'>
          <div className='w-[100px] h-[100px] flex-shrink-0'>
            <div className='w-full h-full overflow-hidden rounded-lg'>
              <img
                src={product.image}
                alt={product.title}
                className='object-cover w-full h-full'
              />
            </div>
          </div>
          <div className='flex flex-col justify-between flex-grow'>
            <div className='flex flex-row items-center gap-2 mb-2'>
              <h3 className='text-xl font-bold text-left sm:text-2xl md:text-3xl lg:text-4xl opacity-80'>
                {product.title}
              </h3>
              <div className='p-2 text-xs font-semibold text-white rounded-lg whitespace-nowrap bg-cyan-600 sm:text-sm'>
                {product.category}
              </div>
            </div>
            {!isExpanded && (
              <p className='text-sm font-normal text-left opacity-70 line-clamp-2'>
                {product.description}
              </p>
            )}
          </div>
        </div>
        <AnimatePresence initial={false}>
          {isExpanded && (
            <motion.div
              ref={contentRef}
              initial='collapsed'
              animate='expanded'
              exit='collapsed'
              variants={{
                expanded: {opacity: 1, height: 'auto'},
                collapsed: {opacity: 0, height: 0},
              }}
              transition={{
                duration: 0.2,
                ease: 'easeInOut',
              }}
              className='flex flex-col gap-4 sm:flex-row'>
              <div className='flex-grow sm:w-2/3'>
                {isExpanded && (
                  <p className='pb-4 text-sm font-normal text-left opacity-70 '>
                    {product.description}
                  </p>
                )}
                <div className='grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4'>
                  {product.spec.map((spec, index) => (
                    <SpecItem
                      key={index}
                      {...spec}
                    />
                  ))}
                  {product.files && product.files.manual && (
                    <FileButton
                      icon={
                        <IconFileTypePdf
                          size={24}
                          stroke={1.5}
                        />
                      }
                      label='产品手册'
                      fileUrl={product.files.manual}
                      className='bg-cyan-700/30 text-neutral-700 hover:bg-cyan-700 hover:text-neutral-100 hover:shadow-md'
                    />
                  )}
                  {product.files && product.files.specSheet && (
                    <FileButton
                      icon={
                        <IconFileTypeXls
                          size={24}
                          stroke={1.5}
                        />
                      }
                      label='技术数据表'
                      fileUrl={product.files.specSheet}
                      className='bg-cyan-700/30 text-neutral-700 hover:bg-cyan-700 hover:text-neutral-100 hover:shadow-md'
                    />
                  )}
                </div>
                <div className='flex flex-row justify-between w-full pt-4'>
                  <div className='w-2/3'>
                    {product.applications && product.applications.length > 0 && (
                      <div className='flex flex-wrap gap-1'>
                        {product.applications.map((app, index) => (
                          <ApplicationTag
                            key={`${app}-${index}`} // 修改这里
                            application={app}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                  <div className='flex flex-col w-1/3 item-left'>
                    {product.package &&
                      product.package.length > 0 &&
                      product.package.map((detail, index) => (
                        <p className='py-2 text-sm font-normal text-left h-fit opacity-70 line-clamp-2'>
                          {t('tag.packaging')}:{detail}
                        </p>
                      ))}
                    {product.alike && product.alike.length > 0 && (
                      <p className='py-2 text-sm font-normal text-left h-fit opacity-70 line-clamp-2'>
                        {t('tag.alike')}:
                        {product.alike.map((detail, index) => (
                          <span key={index}>
                            {detail}
                            {index < product.alike.length - 1 ? ', ' : ''}
                          </span>
                        ))}
                      </p>
                    )}
                    <button
                      onClick={handleLearnMore}
                      className='px-4 py-2 mt-auto ml-auto font-bold text-white transition-colors duration-200 rounded-md w-fit h-fit bg-cyan-600 hover:bg-cyan-700'>
                      {t('tag.get_sample')}
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

const ProductCard = ({product, isExpanded, onExpand}) => {
  const toggleExpand = useCallback(() => {
    onExpand();
  }, [onExpand]);

  if (isMobile) {
    return (
      <MobileProductCard
        product={product}
        toggleExpand={toggleExpand}
        isExpanded={isExpanded}
      />
    );
  }

  return (
    <DesktopProductCard
      product={product}
      toggleExpand={toggleExpand}
      isExpanded={isExpanded}
    />
  );
};

export default ProductCard;

const ApplicationTag = ({application}) => {
  // 移除 index 参数
  const {t} = useTranslation();

  const applicationMap = {
    A: {
      label: t('tag.masterbatch'),
      description: t('tag.wetting_agent'),
      color: 'bg-yellow-300',
      icon: IconPalette,
    },
    B: {
      label: t('tag.hot_melt'),
      description: t('tag.dispersant'),
      color: 'bg-orange-300',
      icon: IconFlame,
    },
    C: {
      label: t('tag.cable_compound'),
      description: t('tag.lubricant_filler'),
      color: 'bg-gray-300',
      icon: IconRoute2,
    },
    D: {
      label: t('tag.ink'),
      description: t('tag.matting_agent'),
      color: 'bg-blue-300',
      icon: IconPaint,
    },
    E: {
      label: t('tag.rubber'),
      description: t('tag.release_agent'),
      color: 'bg-cyan-300',
      icon: IconLeaf,
    },
    F: {
      label: t('tag.pvc_pipe'),
      description: t('tag.stabilizer'),
      color: 'bg-stone-300',
      icon: IconTestPipe2Filled,
    },
    G: {
      label: t('tag.waterproof_coating'),
      description: t('tag.high_temp_modifier'),
      color: 'bg-green-300',
      icon: IconDroplet,
    },
  };

  const {label, description, color, icon: Icon} = applicationMap[application] || {};

  if (!label) return null;

  return (
    <div
      className={`px-3 py-1 mb-2 mr-2 text-sm font-semibold text-gray-700 rounded-full flex items-center ${color}`}>
      <Icon
        size={16}
        className='mr-1'
      />
      <span>{label}</span>
      <span className='ml-1 text-xs text-gray-600'>: {description}</span>
    </div>
  );
};
