import React, { forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CustomQuill = forwardRef(({ value, onChange, modules }, ref) => {
  const quillRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getQuill: () => quillRef.current.getEditor(),
    getHTML: () => quillRef.current.getEditor().root.innerHTML,
  }));

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      if (value !== editor.root.innerHTML) {
        editor.root.innerHTML = value || '';
      }
    }
  }, [value]);

  return (
    <ReactQuill
      ref={quillRef}
      value={value}
      onChange={onChange}
      modules={modules}
    />
  );
});

export default CustomQuill;