import React, {useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {
  IconMail,
  IconPhone,
  IconMapPin,
  IconBrandWechat,
  IconMessageDots,
  IconUser,
  IconCheck,
  IconAlertCircle,
} from '@tabler/icons-react';
import {apiUrl} from '../routes/AdminRoutes';
import debounce from 'lodash/debounce';

function Contact() {
  const {t, i18n} = useTranslation();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    country: '',
    company: '',
    address: '',
    name: '',
    position: '',
    phone: '',
    email: '',
    message: '',
  });
  const [submitStatus, setSubmitStatus] = useState('idle'); // 'idle', 'sending', 'success', 'error'

  const contactInfo = [
    {
      icon: IconUser,
      label: 'contact',
      value: {
        zh: '戚浩燊先生 泰利国际贸易部经理',
        en: 'Mr. Qi, International Trade Manager',
      },
    },
    {
      icon: IconPhone,
      label: 'mobile',
      value: '+86 13702421731',
    },
    {
      icon: IconPhone,
      label: 'landline',
      value: '+86 0758-7789970',
    },
    {
      icon: IconMail,
      label: 'email',
      value: 'tailiqihaoshen@gmail.com',
    },
    {
      icon: IconBrandWechat,
      label: 'whatsapp',
      value: 'TAILI WAX',
    },
    {
      icon: IconMapPin,
      label: 'address',
      value: {
        zh: '中国广东省肇庆市德庆县康杰路 17 号',
        en: 'No. 17, Kangjie Road, Deqing County, Zhaoqing City, Guangdong Province, China',
      },
    },
    {
      icon: IconMapPin,
      label: 'postcode',
      value: '526699',
    },
  ];

  const handleInputChange = (e) => {
    const {id, value} = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = useCallback((e) => {
    e.preventDefault(); // 将 preventDefault 移到这里

    const debouncedSubmit = debounce(async () => {
      const newErrors = {};
      const requiredFields = ['country', 'name', 'email', 'message'];

      requiredFields.forEach((field) => {
        if (!formData[field].trim()) {
          newErrors[field] = t('contact.fieldRequired');
        }
      });

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }

      setErrors({});
      setSubmitStatus('sending');

      const messageString = Object.entries(formData)
        .map(([key, value]) => `${key}: ${value}`)
        .join('\n');

      try {
        const response = await fetch(`${apiUrl}/api/email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({message: messageString}),
        });

        const result = await response.json();

        if (result.status === 'success') {
          setSubmitStatus('success');
          setFormData({
            country: '',
            company: '',
            address: '',
            name: '',
            position: '',
            phone: '',
            email: '',
            message: '',
          });
          setTimeout(() => setSubmitStatus('idle'), 3000);
        } else {
          setSubmitStatus('error');
        }
      } catch (error) {
        console.error('发送邮件时出错:', error);
        setSubmitStatus('error');
      }
    }, 500);

    debouncedSubmit();
  }, [formData, t]);

  const renderField = (field) => (
    <div
      key={field.id}
      className={`${field.fullWidth ? 'col-span-2' : 'col-span-1'}`}>
      <label
        htmlFor={field.id}
        className='block mb-1 text-sm font-medium text-left text-neutral-700 sm:text-base'>
        {t(`contact.${field.label}`)} {field.required && '*'}
      </label>
      {field.multiline ? (
        <textarea
          id={field.id}
          rows='3'
          required={field.required}
          value={formData[field.id]}
          onChange={handleInputChange}
          className={`w-full px-3 py-2 text-sm bg-transparent border-b ${
            errors[field.id] ? 'border-red-500' : 'border-neutral-300'
          } focus:border-cyan-500 focus:outline-none sm:text-base`}
        />
      ) : (
        <input
          type={field.type || 'text'}
          id={field.id}
          required={field.required}
          value={formData[field.id]}
          onChange={handleInputChange}
          className={`w-full px-3 py-2 text-sm bg-transparent border-b ${
            errors[field.id] ? 'border-red-500' : 'border-neutral-300'
          } focus:border-cyan-500 focus:outline-none sm:text-base`}
        />
      )}
      {errors[field.id] && <p className='mt-1 text-sm text-red-500'>{errors[field.id]}</p>}
    </div>
  );

  return (
    <div className='min-h-screen'>
      <div className='container px-4 py-4 mx-auto max-w-7xl sm:px-6 sm:py-8'>
        <div className='grid gap-8 md:grid-cols-2 md:gap-12'>
          <div>
            <h2 className='mb-4 text-xl font-semibold text-left text-neutral-700 sm:text-2xl sm:mb-6'>
              {t('contact.getInTouch')}
            </h2>
            <div className='space-y-4'>
              {contactInfo.map((item, index) => (
                <div
                  key={index}
                  className='flex flex-col gap-2 sm:flex-row sm:items-center sm:justify-between'>
                  <div className='flex items-center'>
                    <item.icon className='flex-shrink-0 w-5 h-5 mr-3 text-cyan-600 sm:w-6 sm:h-6 sm:mr-4' />
                    <strong className='text-sm font-medium text-neutral-800 sm:text-base'>
                      {t(`contact.${item.label}`)}
                    </strong>
                  </div>
                  <span className='pl-8 text-sm font-medium text-left text-neutral-600 sm:text-base sm:pl-0 md:text-right'>
                    {typeof item.value === 'object' ? item.value[i18n.language] : item.value}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div>
            <h2 className='mb-4 text-xl font-semibold text-left text-neutral-700 sm:text-2xl sm:mb-6'>
              {t('contact.sendMessage')}
            </h2>
            <form
              onSubmit={handleSubmit}
              className='grid grid-cols-2 gap-4 sm:gap-6'>
              {[
                {id: 'country', label: 'country', required: true},
                {id: 'company', label: 'company'},
                {id: 'address', label: 'address', fullWidth: true},
                {id: 'name', label: 'name', required: true},
                {id: 'position', label: 'position'},
                {id: 'phone', label: 'phone'},
                {id: 'email', label: 'email', required: true, type: 'email'},
                {id: 'message', label: 'message', required: true, multiline: true, fullWidth: true},
              ].map(renderField)}
              <div className='col-span-2'>
                <button
                  type='submit'
                  disabled={submitStatus === 'sending' || submitStatus === 'success'}
                  className={`flex items-center justify-center w-full gap-2 p-2 mt-4 font-bold text-white transition-colors rounded-lg focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-opacity-50 ${
                    submitStatus === 'idle'
                      ? 'bg-cyan-700/80 hover:bg-cyan-600/80'
                      : submitStatus === 'sending'
                      ? 'bg-yellow-500 cursor-not-allowed'
                      : submitStatus === 'success'
                      ? 'bg-green-500 cursor-not-allowed'
                      : 'bg-red-500 hover:bg-red-600'
                  }`}
                  aria-label={t('contact.send')}>
                  {submitStatus === 'idle' && <IconMessageDots size={24} />}
                  {submitStatus === 'sending' && <IconMessageDots size={24} className='animate-spin' />}
                  {submitStatus === 'success' && <IconCheck size={24} />}
                  {submitStatus === 'error' && <IconAlertCircle size={24} />}
                  {submitStatus === 'idle' && t('contact.send')}
                  {submitStatus === 'sending' && t('contact.sending')}
                  {submitStatus === 'success' && t('contact.sent')}
                  {submitStatus === 'error' && t('contact.retry')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
