import React, {useState, useRef, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {IconChevronUp, IconChevronDown} from '@tabler/icons-react';
import {isMobile} from 'react-device-detect';

const languages = [
  {code: 'zh', name: 'CN', flag: '🇨🇳'},
  {code: 'en', name: 'EN', flag: '🇬🇧'},
];

const LanguageSwitcher = ({position = 'bottom'}) => {
  const {i18n} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const currentLanguage = languages.find((lang) => lang.code === i18n.language) || languages[1];

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderLanguageButton = (lang) => (
    <button
      key={lang.code}
      onClick={() => changeLanguage(lang.code)}
      className='flex items-center w-full px-4 py-2 text-sm text-white transition-colors duration-150 hover:bg-white/10 whitespace-nowrap'
      role='menuitem'>
      <span className='mr-3'>{lang.flag}</span>
      <span>{lang.name}</span>
    </button>
  );

  if (isMobile) {
    return (
      <div
        className='w-full'
        ref={dropdownRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className='flex items-center justify-between w-full text-base font-semibold text-white transition-colors'>
          {isOpen ? <IconChevronUp size={20} /> : <IconChevronDown size={20} />}
          <div className='flex items-center'>
            <span className='mr-2'>{currentLanguage.flag}</span>
            <span>{currentLanguage.name}</span>
          </div>
        </button>
        {isOpen && (
          <div className='bg-cyan-700'>
            {languages
              .filter((lang) => lang.code !== currentLanguage.code)
              .map(renderLanguageButton)}
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className='relative'
      ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className='flex items-center justify-between px-3 py-2 text-sm font-medium text-white transition-colors duration-200 rounded-lg bg-cyan-800/80 hover:bg-cyan-700/80 backdrop-blur-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-opacity-50'>
        <span className='mr-2'>{currentLanguage.flag}</span>
        <span className='mr-2'>{currentLanguage.name}</span>
        <IconChevronDown
          size={16}
          className={`transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
        />
      </button>

      {isOpen && (
        <div
          className={`absolute ${
            position === 'bottom' ? 'top-full mt-2' : 'bottom-full mb-2'
          } right-0 origin-top-right bg-neutral-800/60 backdrop-blur-md rounded-lg shadow-lg ring-1 ring-white/10 focus:outline-none z-50 overflow-hidden transition-all duration-200 ease-out`}
          style={{
            transform: isOpen ? 'scale(1)' : 'scale(0.95)',
            opacity: isOpen ? 1 : 0,
          }}>
          <div
            className='py-1'
            role='menu'
            aria-orientation='vertical'
            aria-labelledby='options-menu'>
            {languages
              .filter((lang) => lang.code !== currentLanguage.code)
              .map(renderLanguageButton)}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
