import {Transition} from '@headlessui/react';
import {
  IconCalendarEvent,
  IconInfoCircle,
  IconMenu2,
  IconPackage,
  IconPhone,
  IconX,
} from '@tabler/icons-react';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from 'react-responsive';
import {Link} from 'react-router-dom';
import SearchBox from './SearchBox';
import LanguageSwitcher from './LanguageSwitcher';
import TailiLogo from '../image/TailiLogo_Text.svg';

function Navbar() {
  const {t} = useTranslation();
  const isMobile = useMediaQuery({maxWidth: 768});
  const [isOpen, setIsOpen] = useState(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  const navItems = [
    {name: 'navbar.products', icon: <IconPackage size={22} />, path: '/products'},
    {name: 'navbar.about', icon: <IconInfoCircle size={22} />, path: '/about'},
    {name: 'navbar.news_and_events', icon: <IconCalendarEvent size={22} />, path: '/news'},
    {name: 'navbar.contact', icon: <IconPhone size={22} />, path: '/contact'},
  ];

  const MobileNavLinks = () => (
    <>
      {navItems.map((item) => (
        <Link
          key={item.name}
          to={item.path}
          className='flex items-center justify-between w-full px-3 py-3 text-base font-semibold text-white transition-colors hover:text-cyan-100 hover:bg-cyan-700'
          onClick={() => setIsOpen(false)}>
          {item.icon}
          <span>{t(item.name)}</span>
        </Link>
      ))}
      <div className='px-3 py-3'>
        <LanguageSwitcher position='top' />
      </div>
    </>
  );

  const DesktopNavLinks = () => (
    <>
      {navItems.map((item) => (
        <Link
          key={item.name}
          to={item.path}
          className={`px-2 py-2 text-sm font-bold text-neutral-200 hover:text-cyan-200 transition-all duration-300 relative group flex items-center justify-center ${
            isSearchExpanded ? 'w-10 h-10' : 'w-auto'
          }`}
          title={t(item.name)}>
          <span
            className={`transition-all duration-300 uppercase ${
              isSearchExpanded ? 'opacity-0 absolute' : 'opacity-100'
            }`}>
            {t(item.name)}
          </span>
          <span
            className={`transition-all duration-300 absolute ${
              isSearchExpanded ? 'opacity-100 scale-100' : 'opacity-0 scale-0'
            }`}>
            {item.icon}
          </span>
          <span className='absolute bottom-0 left-0 w-full h-0.5 bg-cyan-200 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-200 ease-in-out'></span>
        </Link>
      ))}
    </>
  );

  return (
    <nav className={`shadow-md transition-colors duration-200 bg-neutral-800`}>
      <div className='px-2 mx-auto max-w-7xl sm:px-4 lg:px-6'>
        <div className='flex items-center justify-between h-16'>
          <div className='flex items-center'>
            <div className='flex-shrink-0'>
              <Link
                to='/'
                className='flex items-center'>
                <img
                  className='w-auto h-6 ml-2'
                  src={TailiLogo}
                  alt='Taili Logo'
                />
              </Link>
            </div>
          </div>
          {isMobile ? (
            <div className='flex items-center'>
              <button
                onClick={() => setIsOpen(!isOpen)}
                className={`inline-flex items-center justify-center p-2 rounded-md ${
                  isOpen
                    ? ' hover:text-cyan-200 hover:bg-cyan-600'
                    : ' hover:text-cyan-600 hover:bg-cyan-50'
                } focus:outline-none text-white focus:ring-2 focus:ring-inset focus:ring-cyan-200 transition-colors`}>
                {isOpen ? <IconX size={24} /> : <IconMenu2 size={24} />}
              </button>
            </div>
          ) : (
            <div className='hidden md:flex md:items-center md:space-x-4'>
              <DesktopNavLinks />
              {/* <div className='flex items-center space-x-2'>
                <SearchBox onSearchExpand={setIsSearchExpanded} />
              </div> */}
              <LanguageSwitcher />
            </div>
          )}
        </div>
      </div>
      {isMobile && (
        <Transition
          show={isOpen}
          enter='transition-all duration-200 ease-out'
          enterFrom='max-h-0 opacity-0'
          enterTo='max-h-[500px] opacity-100'
          leave='transition-all duration-200 ease-in'
          leaveFrom='max-h-[500px] opacity-100'
          leaveTo='max-h-0 opacity-0'
          className='overflow-hidden'>
          <div className='md:hidden bg-neutral-800'>
            <div className='flex flex-col items-stretch divide-y divide-neutral-500/20'>
              <MobileNavLinks />
            </div>
          </div>
        </Transition>
      )}
    </nav>
  );
}

export default Navbar;
