import React, {useState, useEffect} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Tag from './Tag'; // 确保您有一个 Tag 组件

const NewsPage = () => {
  const {id} = useParams();
  const {t, i18n} = useTranslation();
  const location = useLocation();
  const [news, setNews] = useState(location.state?.news || null);
  const [loading, setLoading] = useState(!news);
  const [error, setError] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  useEffect(() => {
    const fetchNewsDetails = async () => {
      try {
        setLoading(true);
        const apiLanguage = i18n.language === 'zh' ? 'cn' : 'en';
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/news/${id}`, {
          headers: {
            'X-Custom-Language': apiLanguage,
          },
        });

        if (!response.ok) {
          throw new Error('获取新闻详情失败');
        }

        const data = await response.json();
        setNews(data);
        setLoading(false);
        setCurrentLanguage(i18n.language);
      } catch (err) {
        console.error('Error fetching news details:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    if (i18n.language !== currentLanguage || !news || !news.content) {
      fetchNewsDetails();
    }
  }, [id, i18n.language, currentLanguage, news]);

  if (loading) {
    return <div className='flex items-center justify-center h-screen'>{t('loading')}</div>;
  }

  if (error || !news) {
    return <div className='flex items-center justify-center h-screen'>{t('newsNotFound')}</div>;
  }

  return (
    <div className='flex justify-center px-4 py-8'>
      <div className='w-full max-w-7xl'>
        <h1 className='mb-4 text-3xl font-bold text-center'>{news.title}</h1>
        <p className='mb-2 text-center text-gray-600'>{news.date}</p>
        <div className='flex justify-center mb-4 space-x-2'>
          {news.tags &&
            news.tags.map((tag, index) => (
              <Tag
                key={index}
                text={tag}
                className='bg-neutral-200 text-neutral-700'
              />
            ))}
        </div>
        {news.image && (
          <div className='mb-6'>
            <img
              src={news.image}
              alt={news.title}
              className='object-cover w-full h-64 rounded-lg md:h-96'
            />
          </div>
        )}
        <div
          className='mx-auto prose lg:prose-xl [&_iframe]:w-full [&_iframe]:aspect-video [&_iframe]:object-contain [&_video]:w-full [&_video]:aspect-video [&_video]:object-contain [&_img]:w-full [&_img]:h-auto [&_img]:object-contain'
          dangerouslySetInnerHTML={{__html: news.content}}
        />
      </div>
    </div>
  );
};

export default NewsPage;
