import React from 'react';
import {IconTrash} from '@tabler/icons-react';

const ProductList = ({products, onEdit, onDelete}) => {
  const handleDelete = (e, productId) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete(productId);
  };

  return (
    <div className='w-1/5 overflow-auto'>
      <ul className='space-y-2'>
        {products.map((product) => (
          <li
            key={product.id}
            className='p-2 transition duration-150 border rounded-md cursor-pointer hover:bg-gray-50'
            onClick={() => onEdit(product)}>
            <div className='flex items-center justify-between mb-2'>
              <h3 className='font-semibold'>{product.title}</h3>
            </div>
            <div className='flex items-center justify-between'>
              <p className='text-sm text-gray-600'>{product.category}</p>
              <button
                onClick={(e) => handleDelete(e, product.id)}
                className='flex items-center justify-center w-8 h-8 text-red-700 transition duration-150 bg-red-100 rounded hover:bg-red-200'
                title='删除'>
                <IconTrash className='w-5 h-5' />
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductList;