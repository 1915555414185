import React, {useState, useEffect, useRef} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import {apiUrl} from '../routes/AdminRoutes';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

function ArticleSlide() {
  const {i18n} = useTranslation();
  const [articles, setArticles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isInView, setIsInView] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const containerRef = useRef(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const currentLanguage = i18n.language;
        const apiLanguage = currentLanguage === 'zh' ? 'cn' : 'en';
        const response = await fetch(`${apiUrl}/banner/news`, {
          headers: {
            'X-Custom-Language': apiLanguage,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setArticles(data);
        setIsLoading(false);
      } catch (error) {
        console.error('获取文章数据失败:', error);
        setIsLoading(false);
      }
    };

    fetchArticles();
  }, [i18n.language]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % articles.length);
    }, 3000); // 每3秒切换一次

    return () => clearInterval(interval);
  }, [articles.length]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '-50% 0px -50% 0px', // 当组件的中心点进入视口时触发
        threshold: 0,
      }
    );

    const currentRef = containerRef.current; // 捕获当前的 ref 值

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={`relative w-full overflow-hidden transition-colors duration-500 ${
        isInView ? 'bg-neutral-800' : 'bg-white'
      }`}
      style={{height: '400px'}}>
      <AnimatePresence initial={false}>
        <motion.div
          key={currentIndex}
          initial={{x: '100%'}}
          animate={{x: 0}}
          exit={{x: '-100%'}}
          transition={{type: 'tween', duration: 0.5}}
          className='absolute top-0 left-0 w-full h-full'>
          {isLoading ? (
            <div className='flex items-center justify-center h-full'>
              <p>加载中...</p>
            </div>
          ) : articles.length === 0 ? (
            <div className='flex items-center justify-center h-full'>
              <p>暂无文章</p>
            </div>
          ) : (
            <div className='flex flex-col h-full'>
              <div className='relative flex-grow overflow-hidden'>
                <img
                  src={articles[currentIndex]?.image}
                  alt={articles[currentIndex]?.title}
                  className='absolute top-0 left-0 object-cover w-full h-full'
                />
              </div>
              <div
                className={`p-4 transition-colors duration-500 ${
                  isInView ? 'bg-neutral-800' : 'bg-white'
                }`}>
                <h3
                  className={`mb-2 text-sm font-semibold transition-colors duration-500 ${
                    isInView ? 'text-cyan-400' : 'text-cyan-700'
                  }`}>
                  {articles[currentIndex]?.tags.join(', ')}
                </h3>
                <h2
                  className={`mb-2 text-xl font-bold line-clamp-2 transition-colors duration-500 ${
                    isInView ? 'text-white' : 'text-neutral-900'
                  }`}>
                  {articles[currentIndex]?.title}
                </h2>
                <Link
                  to={`/news/${articles[currentIndex]?.id}`}
                  state={{news: articles[currentIndex]}}
                  className={`inline-block px-4 py-2 font-semibold rounded-lg transition-colors duration-500 select-none ${
                    isInView
                      ? 'text-neutral-900 bg-white hover:bg-neutral-200'
                      : 'text-white bg-cyan-700 hover:bg-cyan-600'
                  }`}>
                  了解更多
                </Link>
              </div>
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export default ArticleSlide;
