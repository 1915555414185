import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

const ProductDetail = () => {
  const {id} = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      const response = await fetch(`/api/products/${id}`);
      const data = await response.json();
      setProduct(data);
    };

    fetchProduct();
  }, [id]);

  if (!product) {
    return <div className='mt-8 text-center'>加载中...</div>;
  }

  return (
    <div className='container px-4 py-8 mx-auto'>
          PRODUCT DETAIL
    </div>
  );
};

export default ProductDetail;
