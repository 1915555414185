import * as qiniu from 'qiniu-js';
import Cookies from 'js-cookie';
import {apiUrl} from '../../routes/AdminRoutes';

export const uploadToQiniu = async (file, onProgress, fileid) => {
  const sessionId = Cookies.get('sessionId');
  try {
    // 从后端获取上传token
    const response = await fetch(`${apiUrl}/api/qiniu-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({session_id: sessionId}),
    });
    if (!response.ok) {
      throw new Error(`获取上传token失败: ${response.status}`);
    }
    const {upload_token} = await response.json();
    if (!upload_token) {
      throw new Error('上传token不存在');
    }
    const config = {
      useCdnDomain: true,
      region: 'as0', // 直接使用字符串 'as0' 表示新加坡区域
      debugLogLevel: 'INFO',
    };
    const putExtra = {
      fname: file.name,
      mimeType: ['image/png', 'image/jpeg', 'image/gif'],
    };

    // 修改这里,将 fileid 作为目录前缀
    const key = fileid || undefined;

    return new Promise((resolve, reject) => {
      const observable = qiniu.upload(file, key, upload_token, putExtra, config);

      const subscription = observable.subscribe({
        next: (res) => {
          onProgress(res.total.percent);
        },
        error: (err) => {
          reject(new Error('七牛云上传失败: ' + err.message));
        },
        complete(res) {
          const domain = `https://cdn.tailiwax.com/`;
          const imageUrl = domain + res.key;
          resolve(imageUrl);
        },
      });

      return subscription;
    });
  } catch (error) {
    console.error('上传过程中出错:', error);
    throw error;
  }
};

export const uploadFileToQiniu = async (file, onProgress, fileid) => {
  const sessionId = Cookies.get('sessionId');
  try {
    // 从后端获取上传token
    const response = await fetch(`${apiUrl}/api/qiniu-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({session_id: sessionId}),
    });
    if (!response.ok) {
      throw new Error(`获取上传token失败: ${response.status}`);
    }
    const {upload_token} = await response.json();
    if (!upload_token) {
      throw new Error('上传token不存在');
    }
    const config = {
      useCdnDomain: true,
      region: 'as0', // 直接使用字符串 'as0' 表示新加坡区域
      debugLogLevel: 'INFO',
    };
    const putExtra = {
      fname: file.name,
      mimeType: [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ],
    };

    // 使用 fileid 作为目录前缀
    const key = fileid ? `${fileid}/${file.name}` : file.name;

    return new Promise((resolve, reject) => {
      const observable = qiniu.upload(file, key, upload_token, putExtra, config);

      const subscription = observable.subscribe({
        next: (res) => {
          onProgress(res.total.percent);
        },
        error: (err) => {
          reject(new Error('七牛云上传失败: ' + err.message));
        },
        complete(res) {
          const domain = 'https://cdn.tailiwax.com/';
          const fileUrl = domain + res.key;
          resolve(fileUrl);
        },
      });

      return subscription;
    });
  } catch (error) {
    console.error('上传过程中出错:', error);
    throw error;
  }
};

export const deleteQiniuFile = async (fileKey) => {
  const sessionId = Cookies.get('sessionId');
  try {
    const response = await fetch(`${apiUrl}/api/delete-qiniu-file?file_key=${encodeURIComponent(fileKey)}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-Session-Id': sessionId
      }
    });

    if (!response.ok) {
      throw new Error(`删除文件失败: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('删除文件过程中出错:', error);
    throw error;
  }
};