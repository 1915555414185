import React, {useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Dropdown from './Dropdown';

const APPLICATION_MAP = {
  A: {key: 'tag.masterbatch'},
  B: {key: 'tag.hot_melt'},
  C: {key: 'tag.cable_compound'},
  D: {key: 'tag.ink'},
  E: {key: 'tag.rubber'},
  F: {key: 'tag.pvc_pipe'},
  G: {key: 'tag.waterproof_coating'},
};

const SORT_FIELDS = ['viscosity', 'softeningPoint'];

const ProductFilter = ({products, onFilterChange}) => {
  const {t} = useTranslation();
  const [activeFilter, setActiveFilter] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedApplication, setSelectedApplication] = useState(null);

  const categories = useMemo(
    () => [...new Set(products.map((product) => product.category))],
    [products]
  );
  const applications = useMemo(
    () => [...new Set(products.flatMap((product) => product.applications))],
    [products]
  );

  const handleFilterChange = (key, value) => {
    const isResetting = activeFilter === key && value === null;
    setActiveFilter(isResetting ? null : key);
    if (key === 'category') setSelectedCategory(isResetting ? null : value);
    if (key === 'application') setSelectedApplication(isResetting ? null : value);
    setSortBy(null);
    setSortOrder('asc');
    onFilterChange(isResetting ? {} : {[key]: value}, null, 'asc');
  };

  const handleSortChange = (field) => {
    const newSortOrder = sortBy === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortBy(field);
    setSortOrder(newSortOrder);
    setActiveFilter(null);
    onFilterChange({}, field, newSortOrder);
  };

  const getSortLabel = (field) => (sortBy !== field ? '⇅' : sortOrder === 'asc' ? '↑' : '↓');

  const renderFilterButton = (key, value, label) => (
    <button
      key={value || 'all'}
      value={value}
      className='block w-full px-4 py-2 text-sm text-left transition-colors duration-150 ease-in-out text-neutral-700 hover:bg-cyan-700 hover:text-white'
      onClick={() => handleFilterChange(key, value)}>
      {label}
    </button>
  );

  return (
    <div className='flex flex-wrap gap-4'>
      <Dropdown
        label={t('productFilter.category')}
        active={activeFilter === 'category'}
        selectedValue={selectedCategory}>
        {renderFilterButton('category', null, t('productFilter.all'))}
        {categories.map((category) => renderFilterButton('category', category, category))}
      </Dropdown>

      <Dropdown
        label={t('productFilter.application')}
        active={activeFilter === 'application'}
        selectedValue={selectedApplication}>
        {renderFilterButton('application', null, t('productFilter.all'))}
        {applications.map((app) =>
          renderFilterButton('application', app, t(APPLICATION_MAP[app]?.key || app))
        )}
      </Dropdown>

      {SORT_FIELDS.map((field) => (
        <button
          key={field}
          className={`px-4 py-2 text-sm font-medium rounded-md text-neutral-700 ${
            sortBy === field ? 'bg-blue-200' : 'bg-neutral-100'
          } hover:bg-neutral-200`}
          onClick={() => handleSortChange(field)}>
          {t(`productFilter.sortBy${field.charAt(0).toUpperCase() + field.slice(1)}`)}{' '}
          {getSortLabel(field)}
        </button>
      ))}
    </div>
  );
};

export default ProductFilter;
