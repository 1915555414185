import React from 'react';
import {IconEdit, IconSend, IconTrash} from '@tabler/icons-react';
import Tag from '../Tag';

const ArticleList = ({articles, onEdit, onDelete, onPublish}) => {
  return (
    <div className='p-4 overflow-y-auto whitespace-nowrap w-fit min-w-72'>
      {articles.map(
        (article) =>
          article.cn && (
            <div key={article.id} className='p-3 mb-3 transition duration-150 border rounded hover:bg-gray-50'>
              <div className='flex items-center justify-between mb-2'>
                <h3 className='flex-grow mr-2 font-semibold text-left truncate'>{article.cn.title}</h3>
              </div>
              <div className='flex flex-row items-center justify-start gap-2 mb-2'>
                <Tag
                  text={article.published ? '已发布' : '草稿'}
                  className={article.published ? 'bg-neutral-200 text-neutral-900' : 'bg-yellow-200 text-yellow-900'}
                />
                <div className='text-xs text-gray-600'>{article.date}</div>
              </div>

              <div className='flex flex-wrap gap-1 mb-2'>
                {article.cn.tags && article.cn.tags.map((tag, index) => <Tag key={index} text={tag} className='text-blue-800 bg-blue-100' />)}
              </div>
              <div className='flex gap-2 text-sm'>
                <button
                  onClick={() => onEdit(article)}
                  className='p-1 text-blue-700 transition duration-150 bg-blue-100 rounded hover:bg-blue-200'
                  title='编辑'>
                  <IconEdit size={18} />
                </button>
                {!article.published && (
                  <button
                    onClick={() => onPublish(article)}
                    className='p-1 text-green-700 transition duration-150 bg-green-100 rounded hover:bg-green-200'
                    title='发布'>
                    <IconSend size={18} />
                  </button>
                )}
                {article.en && <Tag text='双语' className='text-purple-800 bg-purple-100' />}
                <button
                  onClick={() => onDelete(article.id)}
                  className='p-1 text-red-700 transition duration-150 bg-red-100 rounded hover:bg-red-200'
                  title='删除'>
                  <IconTrash size={18} />
                </button>
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default ArticleList;
