import React, {useEffect, useRef, useState, useMemo} from 'react';
import 'tailwindcss/tailwind.css';
import TextareaAutosize from 'react-textarea-autosize';
import {IconSend, IconSearch, IconMail} from '@tabler/icons-react';
import {motion, AnimatePresence} from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

// 假数据
const fakeChatHistory = {
  zh: [
    {id: 1, message: '您好！欢迎了解泰利新材料。我们能为您做些什么？', sender: 'bot'},
    {id: 2, message: '你们主要生产什么产品？', sender: 'user'},
    {
      id: 3,
      message:
        '我们专注于高品质聚乙烯蜡的研发和生产。我们的产品以高白度、低气味和优异的热稳定性著称。',
      sender: 'bot',
    },
    {id: 4, message: '这些蜡产品主要用在哪里？', sender: 'user'},
    {
      id: 5,
      message:
        '我们的产品广泛应用于色母粒、PVC制品、涂料等领域。泰利始终秉承"用心做好蜡"的理念，为客户提供创新解决方案。',
      sender: 'bot',
    },
  ],
  en: [
    {id: 1, message: 'Welcome to Taili New Materials. How can we help you today?', sender: 'bot'},
    {id: 2, message: 'What are your main products?', sender: 'user'},
    {
      id: 3,
      message:
        'We specialize in high-quality polyethylene wax. Our products are known for their high whiteness, low odor, and excellent thermal stability.',
      sender: 'bot',
    },
    {id: 4, message: 'Where are these wax products mainly used?', sender: 'user'},
    {
      id: 5,
      message:
        'Our products are widely used in masterbatches, PVC products, coatings, and more. At Taili, we\'re committed to "Making wax with heart" and providing innovative solutions for our customers.',
      sender: 'bot',
    },
  ],
};

// 聊天记录组件
function ChatHistory({chatHistory}) {
  const chatContainerRef = useRef(null);

  useEffect(() => {
    const updateMaxHeight = () => {
      if (chatContainerRef.current) {
        const viewportHeight = window.innerHeight;
        chatContainerRef.current.style.maxHeight = `${(viewportHeight * 2) / 3}px`;
      }
    };

    updateMaxHeight();
    window.addEventListener('resize', updateMaxHeight);

    return () => window.removeEventListener('resize', updateMaxHeight);
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]);

  return (
    <motion.div
      ref={chatContainerRef}
      layout
      className='flex flex-col p-4 space-y-4 overflow-y-auto font-light sm:space-y-6 md:space-y-8 scrollbar-hide'
      style={{
        overflowY: 'auto',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
      }}>
      {chatHistory.map((chat, index) => (
        <motion.div
          key={chat.id}
          initial={{opacity: 0, y: 20}}
          animate={{opacity: 1, y: 0}}
          transition={{duration: 0.3, delay: index * 0.1}}
          className={`flex ${chat.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
          <div
            className={`p-3 sm:p-4 rounded-lg text-white outline outline-1 shadow-[inset_0_0_30px_5px_rgba(255,255,255,0.1)] outline-neutral-100/50 outline-offset-0  bg-opacity-20 ${
              chat.sender === 'bot' ? 'bg-neutral-100/10 text-left' : 'bg-neutral-900/20 text-right'
            } text-sm sm:text-base md:text-lg lg:text-xl prose prose-invert`}>
            <ReactMarkdown>{chat.message}</ReactMarkdown>
          </div>
        </motion.div>
      ))}
    </motion.div>
  );
}

function ChatInput({onSend, onFocus, isExpanded}) {
  const [input, setInput] = useState('');
  const textareaRef = useRef(null);
  const {i18n} = useTranslation();

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = () => {
    if (input.trim()) {
      onSend(input);
      setInput('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleQuickAction = (prompt) => {
    setInput(prompt);
    textareaRef.current.focus();
  };

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };

  return (
    <div className='w-full p-4 font-normal'>
      <div className='flex items-stretch space-x-4'>
        <TextareaAutosize
          ref={textareaRef}
          value={input}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          placeholder={i18n.language === 'en' ? 'Ask AI about TAILI' : '输入任何关于泰利的问题'}
          className='flex-grow p-4 text-base text-white placeholder-gray-400 bg-transparent rounded-lg shadow-md resize-none scrollbar-hide placeholder:text-neutral-200 hover:shadow-[inset_0_0_30px_5px_rgba(255,255,255,0.2)] 
          focus:outline-2 sm:text-lg md:text-xl lg:text-2xl outline outline-neutral-100/50 hover:outline-2 hover:outline-neutral-100 min-h-[56px]'
          minRows={1}
          maxRows={6}
        />
        <button
          onClick={handleSubmit}
          className='flex items-center justify-center w-14 h-14 sm:w-16 sm:h-16 md:w-18 md:h-18 text-white bg-transparent rounded-lg shadow-md bg-opacity-80 hover:bg-transparent outline outline-neutral-100/50 hover:outline-2 hover:outline-neutral-100 hover:shadow-[inset_0_0_30px_5px_rgba(255,255,255,0.2)]'>
          <IconSend className='w-6 h-6 sm:w-7 sm:h-7 md:w-7 md:h-7 lg:w-8 lg:h-8' />
        </button>
      </div>
      <QuickActions onAction={handleQuickAction} />
    </div>
  );
}

function QuickActions() {
  const scrollContainerRef = useRef(null);
  const {t} = useTranslation();
  const navigate = useNavigate();

  const actions = [
    {
      icon: <IconSearch size={24} />,
      label: t('chatbox.searchProduct'),
      description: t('chatbox.searchProductDesc'),
      path: '/products',
    },
    {
      icon: <IconMail size={24} />,
      label: t('chatbox.contactUs'),
      description: t('chatbox.contactUsDesc'),
      path: '/contact',
    },
  ];

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className='w-full mt-6'>
      <div
        ref={scrollContainerRef}
        className='flex flex-col justify-between space-y-4 overflow-x-auto sm:flex-row sm:space-y-0 sm:space-x-4 scrollbar-hide'>
        {actions.map((action, index) => (
          <button
            key={index}
            onClick={() => handleNavigation(action.path)}
            className='flex items-center justify-between  bg-neutral-800/5 p-4 text-white 
            outline outline-1 outline-neutral-100/50 outline-offset-[-2px]
            hover:outline-2 hover:outline-neutral-100
            hover:shadow-[inset_0_0_30px_5px_rgba(224,255,255,0.2)]
            duration-100 transition-all rounded-lg
            w-full sm:flex-1'>
            <div className='flex flex-col items-start'>
              <span className='text-base font-bold sm:text-lg md:text-xl whitespace-nowrap'>
                {action.label}
              </span>
              <span className='hidden mt-2 text-sm text-left sm:block opacity-70'>
                {action.description}
              </span>
            </div>
            <div className='flex-shrink-0 ml-2 sm:ml-0'>
              {React.cloneElement(action.icon, {
                size: 24,
                className: 'w-6 h-6 sm:w-8 sm:h-8 md:w-9 md:h-9',
              })}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}

function ChatBox({isVideoLoaded, videoRef}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const {i18n} = useTranslation();
  const apiUrl = process.env.REACT_APP_API_URL || '';
  const [chatHistory, setChatHistory] = useChatHistory(i18n.language);
  const sendSSEMessage = useSSEChatResponse(apiUrl);
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const getSlogan = () => {
    return i18n.language === 'zh' ? '用心做好蜡' : 'TRUE LOVE FOR WAX CRAFTING';
  };

  const slogan = useMemo(() => getSlogan(), [i18n.language]);
  const displayedSlogan = useTypewriter(slogan, 200);

  useEffect(() => {
    if (isVideoLoaded) {
      const timer = setTimeout(() => {
        setShowPlaceholder(false);
        if (videoRef.current) {
          videoRef.current.play();
        }
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isVideoLoaded, videoRef]);

  const handleSendMessage = async (message) => {
    const userMessage = {id: Date.now(), message, sender: 'user'};
    const botMessage = {id: Date.now() + 1, message: '', sender: 'bot'};

    setChatHistory((prev) => [...prev, userMessage, botMessage]);
    setIsExpanded(true);
    setShowPlaceholder(false);
    await sendSSEMessage(message, setChatHistory);
  };

  const handleInputFocus = () => {
    setIsExpanded(true);
    setShowPlaceholder(false);
  };

  return (
    <motion.div
      layout
      className='flex flex-col w-full max-w-6xl px-2 mx-auto'
      initial={{height: 'auto'}}
      animate={{height: 'auto'}}
      transition={{duration: 0.3, ease: 'easeInOut'}}>
      <AnimatePresence>
        {showPlaceholder && (
          <motion.div
            initial={{opacity: 1, height: 'auto'}}
            animate={{opacity: 1, height: 'auto'}}
            exit={{opacity: 0, height: 0}}
            transition={{duration: 0.3, ease: 'easeInOut'}}
            className='overflow-hidden'>
            <div className='relative flex justify-center w-full max-w-6xl px-5 pb-20'>
              <h1 className='relative text-6xl font-bold text-left text-white'>
                {displayedSlogan}
              </h1>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence initial={false}>
        {isExpanded && chatHistory && chatHistory.length > 0 && (
          <motion.div
            key='chat-history'
            initial={{opacity: 0, height: 0}}
            animate={{opacity: 1, height: 'auto'}}
            exit={{opacity: 0, height: 0}}
            transition={{duration: 0.3}}
            className='mb-2 sm:mb-4 md:mb-6'>
            <ChatHistory chatHistory={chatHistory} />
          </motion.div>
        )}
      </AnimatePresence>
      <ChatInput
        onSend={handleSendMessage}
        onFocus={handleInputFocus}
        isExpanded={isExpanded}
      />
    </motion.div>
  );
}

export default ChatBox;

function useChatHistory(initialLanguage) {
  const [chatHistory, setChatHistory] = useState([]);

  useEffect(() => {
    setChatHistory(fakeChatHistory[initialLanguage]);
  }, [initialLanguage]);

  return [chatHistory, setChatHistory];
}

function useSSEChatResponse(apiUrl) {
  const processSSEResponse = async (response, updateChatHistory) => {
    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    let fullMessage = ''; // 用于存储完整的消息

    const processLine = (line) => {
      if (line.startsWith('data: ')) {
        const data = JSON.parse(line.slice(6));

        if (data.finish === 'stop') {
          updateChatHistory((prev) => {
            const newHistory = [...prev];
            const lastMessage = newHistory[newHistory.length - 1];
            if (lastMessage.sender === 'bot') {
              lastMessage.usage = data.usage;
            }
            return newHistory;
          });
          return true; // 结束处理
        } else if (data.data) {
          fullMessage += data.data; // 追加新的数据片段
          updateChatHistory((prev) => {
            const newHistory = [...prev];
            const lastMessage = newHistory[newHistory.length - 1];
            if (lastMessage.sender === 'bot') {
              lastMessage.message = fullMessage; // 更新为完整的消息
            }
            return newHistory;
          });
        }
      }
      return false;
    };

    while (true) {
      const {value, done} = await reader.read();
      if (done) {
        break;
      }

      const chunk = decoder.decode(value);
      const lines = chunk.split('\n\n');

      for (const line of lines) {
        if (processLine(line)) {
          return; // 如果处理完成，直接返回
        }
      }
    }
  };

  const sendMessage = async (message, updateChatHistory) => {
    try {
      const response = await fetch(`${apiUrl}/api/chat`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({message}),
      });

      if (!response.ok) throw new Error('Network response was not ok');

      await processSSEResponse(response, updateChatHistory);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return sendMessage;
}

function useTypewriter(text, baseSpeed = 100) {
  const [displayedText, setDisplayedText] = useState('');
  const {i18n} = useTranslation();

  useEffect(() => {
    setDisplayedText(''); // 重置文本
    let i = 0;
    const timer = setInterval(() => {
      if (i <= text.length) {
        setDisplayedText(text.slice(0, i));
        i++;
      } else {
        clearInterval(timer);
      }
    }, i18n.language === 'zh' ? baseSpeed : baseSpeed / 2);

    return () => clearInterval(timer);
  }, [text, baseSpeed, i18n.language]);

  return displayedText;
}